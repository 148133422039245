@font-face {
  font-family: "untitled1regular";
  src: url("../fonts/moss-webfont.woff2") format("woff2"), url("../fonts/moss-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
figure,
figcaption,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
}

iframe,
video {
  max-width: 100%;
  width: 100%;
}

strong {
  font-weight: 700;
}

.bold {
  font-weight: 700 !important;
}

.font--12 {
  font-size: 12px !important;
}

.font--18 {
  font-size: 18px !important;
}

.font--0 {
  font-size: 20px !important;
}

.txt-center {
  text-align: center !important;
}

input:not([type=date], [type=number]),
textarea,
button {
  -webkit-appearance: none;
  border-radius: 0;
}

button {
  padding: 0;
}

/* remember to define focus styles! */
:focus {
  outline: none;
}

:focus-visible {
  outline: 2px solid #BC8915;
  outline-offset: 2px;
}

a,
button {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

a {
  color: rgba(241, 238, 229, 0.4);
  text-decoration: none;
}
a:hover {
  color: #BC8915;
}

body {
  line-height: 1.2;
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: rgba(50, 64, 80, 0.8);
  font-style: normal;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

/* tables still need 'cellspacing="0"' in the markup */
table {
  border-collapse: separate;
  border-spacing: 0;
  color: #324050;
}

caption,
th,
td {
  text-align: left;
  font-weight: normal;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
}

h1,
h2,
h3 {
  font-family: freight-big-pro, serif;
  color: #000;
  line-height: 1.2;
  letter-spacing: initial;
}
h1 a,
h2 a,
h3 a {
  color: inherit;
}

h1,
.h1 {
  font-size: 95px;
  margin-bottom: 30px;
  font-weight: 300;
}

h2 {
  font-size: 40px;
  margin-bottom: 25px;
  font-weight: 300;
}

h3 {
  font-size: 32px;
  margin-bottom: 15px;
  margin: 20px 0 15px;
  font-weight: 500;
}

.title {
  margin-bottom: 50px;
  font-size: 78px;
  font-weight: 300;
  line-height: 0.6;
  color: #000;
  position: relative;
  transition: left 0.5s ease-in-out, opacity 1s ease-in-out;
}
.title.wow {
  left: -50px;
  opacity: 0;
}
.title span {
  display: block;
  color: rgba(50, 64, 80, 0.8);
  opacity: 0.7;
  position: relative;
  left: 70px;
}
@media (max-width: 992px) {
  .title {
    font-size: 64px;
    margin-bottom: 30px;
  }
  .title span {
    left: 60px;
  }
}
@media (max-width: 576px) {
  .title {
    font-size: 60px;
  }
  .title span {
    left: 56px;
  }
}
.title.wow-loaded {
  left: 0px;
  opacity: 1;
}

.title--big {
  margin-bottom: 50px;
  font-size: 96px;
  font-weight: 300;
  line-height: 0.6;
  color: #000;
  padding-left: 100px;
}
.title--big span {
  display: block;
  color: rgba(50, 64, 80, 0.8);
  opacity: 0.7;
  position: relative;
  left: -100px;
}

.title--2 {
  font-size: 74px;
  font-family: freight-big-pro, serif;
  font-weight: 300;
}

@media (max-width: 992px) {
  h1,
.h1 {
    font-size: 70px;
  }

  h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 20px;
  }

  .title--2 {
    font-size: 64px;
  }
}
@media (max-width: 576px) {
  h1,
.h1 {
    font-size: 60px;
  }

  h2 {
    font-size: 34px;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 18px;
  }

  .title--2 {
    font-size: 60px;
  }
}
h4,
h5,
h6 {
  line-height: 1.5;
  font-size: 16px;
}

.h--2 {
  margin-bottom: 25px;
  color: #BC8915;
}
@media (max-width: 576px) {
  .h--2 {
    text-align: center;
    font-size: 40px;
  }
}

p,
ul,
ol {
  line-height: 1.6;
}

ul,
ol {
  list-style: none;
}

.page__txt h1,
.page__txt .title--2 {
  font-size: 40px;
}
.page__txt h2 {
  font-size: 30px;
}
.page__txt h3 {
  font-size: 20px;
}
.page__txt ul,
.page__txt ol {
  list-style: initial;
  padding-left: 30px;
}
.page__txt ol,
.page__txt ul,
.page__txt p {
  margin-bottom: 15px;
}
.page__txt li {
  margin-bottom: 5px;
}
.page__txt a {
  text-decoration: underline;
}
@media (max-width: 576px) {
  .page__txt h1,
.page__txt .title--2 {
    font-size: 30px;
  }
  .page__txt h2 {
    font-size: 24px;
  }
  .page__txt h3 {
    font-size: 18px;
  }
}

a,
span,
strong {
  line-height: inherit;
}

.link--underline {
  text-decoration: underline;
  color: rgba(50, 64, 80, 0.8);
}
@media (hover: hover) {
  .link--underline:hover {
    color: #BC8915;
  }
}

.link--underline-main {
  text-decoration: underline;
  color: #BC8915;
}
@media (hover: hover) {
  .link--underline-main:hover {
    color: #000;
  }
}

.link--dark {
  color: rgba(50, 64, 80, 0.8);
}
@media (hover: hover) {
  .link--dark:hover {
    color: #BC8915;
  }
}

.container {
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 540px;
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
  .container.container--md {
    max-width: 960px;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1350px;
  }
}

.container--half {
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 540px;
}
@media (min-width: 768px) {
  .container--half {
    max-width: 360px;
  }
}
@media (min-width: 992px) {
  .container--half {
    max-width: 480px;
  }
}
@media (min-width: 1200px) {
  .container--half {
    max-width: 570px;
  }
}
@media (min-width: 1400px) {
  .container--half {
    max-width: 675px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: 20px;
}

[class*=col-] {
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
}

@media (min-width: 768px) {
  .col-md-50 {
    width: 50%;
    flex: 0 0 50%;
  }
}
@media (min-width: 992px) {
  .col-lg-50 {
    width: 50%;
    flex: 0 0 50%;
  }
}
.header__fixed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}

.img--fluid {
  max-width: 100%;
  height: auto;
}

.box--center {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.nowrap {
  white-space: nowrap !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-100 {
  margin-top: 100px !important;
}
@media (max-width: 768px) {
  .mt-100 {
    margin-top: 50px !important;
  }
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

input,
textarea,
select {
  border: 1px solid #E5F0F1;
  line-height: 1.6;
  padding: 10px 15px;
  color: #E8E9E5;
}
input::-moz-placeholder, textarea::-moz-placeholder, select::-moz-placeholder {
  color: #E5F0F1;
}
input:-ms-input-placeholder, textarea:-ms-input-placeholder, select:-ms-input-placeholder {
  color: #E5F0F1;
}
input::placeholder,
textarea::placeholder,
select::placeholder {
  color: #E5F0F1;
}

.btn2 {
  border: none;
  color: #BC8915;
  text-transform: uppercase;
  padding: 22px;
  font-size: 15px;
  white-space: nowrap;
  position: relative;
  display: inline-block;
  background: #facc5a;
  font-weight: 600;
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.btn {
  border: none;
  background: transparent;
  color: #324050;
  text-transform: uppercase;
  padding: 20px 100px 20px 15px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 700;
  white-space: nowrap;
  position: relative;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  border: 1px solid #324050;
  line-height: 0.5;
}
.btn:after {
  content: url("../img/arrow-right-btn.svg");
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 25px;
  transition: right 0.3s ease-in-out;
}

.btn--light {
  background: rgba(13, 33, 83, 0.1);
  color: #BC8915;
}
.btn--light:after {
  content: url("../img/arrow-right.svg");
}

.btn--white {
  background: #fff;
  color: #BC8915;
  border: 1px solid #E5F0F1;
}
.btn--white:after {
  content: url("../img/arrow-yellow.svg");
}

.btn--back {
  padding: 20px 30px 20px 100px;
}
.btn--back:after {
  right: initial;
  left: 30px;
  transition-property: left;
  content: url("../img/arrow-left-btn.svg");
}

.is--desktop .btn:hover {
  color: #324050;
  outline: none;
  box-shadow: 0 5px 5px -5px rgba(50, 64, 80, 0.8);
}
.is--desktop .btn:hover:after {
  right: 15px;
}
.is--desktop .btn:hover.btn--back:after {
  right: initial;
  left: 15px;
}
.is--desktop .btn__parent:hover .btn {
  color: #324050;
  outline: none;
}
.is--desktop .btn__parent:hover .btn:after {
  right: 15px;
}
.is--desktop .btn2:hover, .is--desktop .btn2:focus {
  outline: none;
  background: #fbdd6b;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 120;
  padding: 30px;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s linear 0.1s, opacity 0.3s ease;
}
.modal.open {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}
.modal .link--underline-main {
  display: block;
  margin-bottom: 10px;
}
.modal p {
  margin-bottom: 20px;
}
.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 21;
  background-color: rgba(0, 0, 0, 0.7);
}
.modal__close {
  position: absolute;
  top: 0px;
  right: 0px;
  border: none;
  outline: none;
  background: none;
  width: 60px;
  height: 60px;
  display: inline-block;
  background: rgba(50, 64, 80, 0.8);
  color: #fff;
  padding: 0;
}
.modal__close:after {
  content: "+";
  transform: rotate(45deg);
  font-size: 40px;
  position: absolute;
  top: 6px;
  left: 20px;
  transition: all 0.3s ease-out;
}
.modal__close:hover {
  background: #BC8915;
}
.modal .modal__content {
  width: 100%;
  overflow: auto;
  max-height: 80vh;
}
.modal__container {
  position: relative;
  z-index: 22;
  max-width: 1520px;
  width: 96%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 5%;
  margin: 0 auto;
  padding: 100px 140px 100px 140px;
  background-color: #fff;
}
@media (max-width: 1200px) {
  .modal__container {
    padding: 65px;
  }
}
@media (max-width: 992px) {
  .modal__container {
    padding: 50px 20px 20px 20px;
    height: auto;
    max-height: 95%;
  }
}
@media (max-width: 576px) {
  .modal__container {
    padding: 40px 20px 20px 20px;
    align-items: flex-start;
  }
}
.modal__title {
  padding-right: 20px;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-size: 40px;
  text-align: left;
  margin-top: 0;
  font-weight: 700;
}
.modal h4 {
  font-family: freight-big-pro, serif;
  font-size: 31px;
  margin-bottom: 10px;
  color: #000;
}
@media (max-width: 992px) {
  .modal__title {
    font-size: 24px;
  }
  .modal h4 {
    font-size: 20px;
    margin-bottom: 5px;
  }
  .modal__close {
    width: 40px;
    height: 40px;
  }
  .modal__close:after {
    top: -3px;
    left: 9px;
  }
}
.modal ul {
  list-style: disc;
  padding-left: 30px;
}

.modal__row {
  position: relative;
}
@media (min-width: 992px) {
  .modal__row {
    padding-bottom: 80px;
  }
}
.modal__row > div {
  width: 50%;
}
.modal__row .img-res {
  height: 100%;
  width: 45%;
  max-width: 45%;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
}
.modal__row .img-res img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}
.modal__row .link__reservation {
  padding: 12px 20px;
}
@media (max-width: 576px) {
  .modal__row {
    padding-bottom: 215px;
  }
  .modal__row > div {
    width: 100%;
  }
  .modal__row .img-res {
    width: 100%;
    max-width: 100%;
    height: 200px;
    top: initial;
    bottom: 0;
  }
  .modal__row .img-res img {
    height: auto;
    position: absolute;
    left: 50%;
    top: 38%;
    transform: translate(-50%, -50%);
  }
  .modal__row p,
.modal__row ul {
    font-size: 14px;
    margin-bottom: 12px;
  }
}
@media (max-width: 576px) and (max-width: 420px) {
  .modal__row {
    padding-bottom: 175px;
  }
  .modal__row .img-res {
    height: 160px;
  }
}

.phone-big {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 35px;
  display: block;
  color: #BC8915;
  font-family: freight-big-pro, serif;
}
@media (max-width: 576px) {
  .phone-big {
    font-size: 26px;
    margin-bottom: 15px;
  }
}

.modal-flex {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-top: 20px;
}
.modal-flex img {
  width: 130px;
}
@media (max-width: 576px) {
  .modal-flex {
    margin-top: 15px;
  }
  .modal-flex img {
    width: 90px;
  }
}

@-webkit-keyframes goTop {
  0% {
    top: 30px;
  }
  25% {
    top: 25px;
  }
  50% {
    top: 30px;
  }
  75% {
    top: 35px;
  }
  100% {
    top: 30px;
  }
}

@keyframes goTop {
  0% {
    top: 30px;
  }
  25% {
    top: 25px;
  }
  50% {
    top: 30px;
  }
  75% {
    top: 35px;
  }
  100% {
    top: 30px;
  }
}
.go-top {
  position: absolute;
  top: 30px;
  right: 80px;
  z-index: 100;
  padding-right: 80px;
  font-weight: 700;
  font-size: 9px;
  text-transform: uppercase;
  transform: rotate(-90deg);
  color: #324050;
}
.go-top:before {
  content: "";
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: block;
  background: #FCFCFA;
  position: absolute;
  top: -30px;
  right: 0;
  transition: background 0.3s ease-in-out;
}
.go-top:after {
  content: url("../img/arrow-top.svg");
  position: absolute;
  top: -14px;
  right: 30px;
  transform: rotate(90deg);
}
@media (hover: hover) {
  .go-top:hover {
    color: rgba(50, 64, 80, 0.8);
    -webkit-animation: goTop 1s ease-in-out infinite;
            animation: goTop 1s ease-in-out infinite;
    transition: top 0.3s ease-in-out;
  }
  .go-top:hover:before {
    background: #E2C3D2;
  }
}
@media (max-width: 1200px) {
  .go-top {
    right: 0px;
  }
}

.go-bottom {
  position: absolute;
  bottom: 50px;
  left: 0px;
  z-index: 100;
  padding-left: 50px;
  font-weight: 700;
  font-size: 9px;
  text-transform: uppercase;
  transform: rotate(-90deg);
  color: #324050;
  z-index: 30;
}
.go-bottom:after {
  content: url("../img/arrow-down-s.svg");
  position: absolute;
  top: -5px;
  left: 30px;
  transform: rotate(90deg);
}
@media (hover: hover) {
  .go-bottom:hover {
    color: #BC8915;
  }
}

.footer {
  background: #f9f8f4;
  position: relative;
  color: #324050;
}
.footer h3 {
  font-size: 40px;
  color: #BC8915;
}

.footer__top {
  padding-top: 70px;
  padding-bottom: 30px;
}
.footer__top ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}
.footer__top ul > li {
  position: relative;
}
.footer__top ul > li a {
  padding: 5px;
  font-size: 12px;
  text-transform: uppercase;
  color: #324050;
}
@media (hover: hover) {
  .footer__top ul > li a:hover {
    color: #BC8915;
  }
}
.footer__top ul > li:after {
  content: "";
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #BC8915;
  position: absolute;
  right: -18px;
  top: 11px;
  font-size: 30px;
}
.footer__top ul > li:last-child:after {
  content: none;
}
@media (max-width: 1200px) {
  .footer__top {
    padding-top: 30px;
  }
  .footer__top ul {
    row-gap: 0px;
    -moz-column-gap: 15px;
         column-gap: 15px;
  }
  .footer__top ul > li:not(:last-child):after {
    right: -9px;
  }
}
@media (max-width: 576px) {
  .footer__top ul {
    flex-direction: column;
    justify-content: left;
    padding-left: 30px;
  }
  .footer__top ul li:after {
    left: -25px;
    right: initial;
  }
  .footer__top ul li:last-child:after {
    content: "";
  }
}

.footer__center {
  display: grid;
  gap: 30px;
  padding-top: 40px;
  padding-bottom: 35px;
}
.footer__center a {
  color: #000;
}
.footer__center a:hover {
  color: #BC8915;
}
@media (min-width: 768px) {
  .footer__center {
    grid-template-columns: 190px 320px 1fr;
    gap: 20px;
  }
}
@media (min-width: 1200px) {
  .footer__center {
    gap: 60px;
    grid-template-columns: 340px 460px 1fr;
  }
}
@media (max-width: 768px) {
  .footer__center {
    text-align: center;
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .footer__center .footer__social img {
    max-width: 30px;
  }
}

.footer__contact {
  font-size: 14px;
}
.footer__contact span:after {
  content: "";
  width: 108px;
  height: 1px;
  background: #E8E9E5;
  display: inline-block;
  margin-left: 10px;
  margin-right: 5px;
  position: relative;
  bottom: 4px;
}
.footer__contact a {
  color: #BC8915;
  font-weight: 700;
}
@media (hover: hover) {
  .footer__contact a:hover {
    color: rgba(50, 64, 80, 0.8);
  }
}
.footer__contact p:nth-child(2) span:after {
  width: 83px;
}

.footer__bottom {
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 16px;
  color: #000;
}
.footer__bottom .container {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 768px) {
  .footer__bottom .container {
    justify-content: center;
  }
}

.footer__social {
  display: flex;
  justify-content: center;
  gap: 25px;
}
.footer__social img {
  transition: all 0.3s ease-in-out;
}
.footer__social a:hover img {
  transform: scale(1.1);
}

.footer__brand {
  color: #9A9A9A;
}
.footer__brand a {
  display: inline-block;
  padding: 5px;
}

.menu-open .header {
  background: #f9f8f4;
}

@-webkit-keyframes fixedMenuDown {
  from {
    top: -100px;
  }
  to {
    top: 0;
  }
}

@keyframes fixedMenuDown {
  from {
    top: -100px;
  }
  to {
    top: 0;
  }
}
.fixed-header-scroll {
  background: #f9f8f4 !important;
  position: fixed;
  top: 0;
  -webkit-animation-name: fixedMenuDown;
          animation-name: fixedMenuDown;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
}
.fixed-header-scroll .header__lang {
  display: none;
}
.fixed-header-scroll .header__wrapper {
  height: 60px;
}
.fixed-header-scroll .header__logo img {
  width: 60px;
}
.fixed-header-scroll.header .link__reservation {
  margin-top: 0;
}
.fixed-header-scroll .nav {
  top: 60px;
}

.header__wrapper {
  display: flex;
  position: relative;
  z-index: 100;
  height: 84px;
  align-items: center;
  justify-content: space-between;
}

.header__logo {
  width: 90px;
}
.header__logo img {
  max-width: 100%;
}

.header__lang {
  position: absolute;
  top: 180px;
  transform: rotate(-90deg) translateY(100%);
  right: -20px;
  display: flex;
}
.header__lang a {
  color: rgba(50, 64, 80, 0.8);
  font-size: 9px;
  display: inline-block;
  padding: 10px 5px;
  text-transform: uppercase;
}
.header__lang a.active {
  font-weight: 700;
}
.header__lang a:hover {
  color: #BC8915;
}

.link__reservation {
  display: inline-block;
  padding: 18px 20px;
  border: 1px solid #BC8915;
  border-radius: 7px;
  text-transform: uppercase;
  font-weight: 600;
  background: transparent;
  color: #BC8915;
  font-size: 12px;
}
.link__reservation:hover {
  background: #BC8915;
  color: #fff;
}
@media (max-width: 1400px) {
  .link__reservation {
    padding: 10px;
  }
}

.header .link__reservation {
  margin-left: 50px;
}
@media (max-width: 1400px) {
  .header .link__reservation {
    margin-left: 20px;
  }
}

.header__phone {
  display: inline-block;
  padding: 10px;
  color: rgba(50, 64, 80, 0.8);
  font-size: 12px;
  font-weight: 700;
}
.header__phone span {
  white-space: nowrap;
}

.nav {
  margin-left: auto;
  margin-right: 50px;
}
.nav ul li {
  position: relative;
}
.nav ul li a {
  padding: 5px;
  color: #324050;
  font-size: 12px;
  text-transform: uppercase;
  display: inline-block;
  white-space: nowrap;
  font-weight: 700;
  position: relative;
  width: 100%;
  outline: none;
}

.is--desktop .nav ul li a:hover {
  color: #BC8915;
}
.is--desktop .nav ul li a:focus {
  text-decoration: underline;
}

.nav > ul {
  list-style: none;
  display: flex;
  gap: 30px;
}
.nav > ul > li:not(:last-child):after {
  content: "";
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #BC8915;
  position: absolute;
  right: -18px;
  top: 12px;
  font-size: 30px;
}
@media (max-width: 1400px) {
  .nav > ul {
    gap: 15px;
  }
  .nav > ul > li:not(:last-child):after {
    right: -9px;
  }
}
.nav > ul ul {
  display: none;
  position: absolute;
  z-index: 100;
  top: 100%;
  left: 0;
  min-width: 250px;
  background: #fff;
  padding: 10px 20px;
}
.nav > ul ul a {
  color: #BC8915;
  line-height: 1.4;
}

.nav-toggle {
  min-width: 50px;
  width: 50px;
  height: 50px;
  padding: 4px 0 20px 0;
  background: transparent;
  border: none;
  display: block;
  display: none;
  position: relative;
  top: 2px;
  margin-left: 50px;
}
.nav-toggle small {
  color: #000;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  position: absolute;
  bottom: 2px;
  right: 0;
}

.nav-toggle span {
  width: 32px;
  height: 2px;
  background: #000;
  transition: all 0.3s ease-in-out;
  display: block;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  border-radius: 1px;
  left: 18px;
  position: absolute;
}
.nav-toggle span.one {
  top: 6px;
}
.nav-toggle span.two {
  top: 16px;
  width: 20px;
  margin-left: 12px;
}
.nav-toggle span.three {
  top: 25px;
}

.nav-toggle.open .two {
  display: none;
}
.nav-toggle.open .one {
  position: absolute;
  top: 17px;
  left: 18px;
  transform: rotate(45deg);
  margin: 0;
}
.nav-toggle.open .three {
  position: absolute;
  top: 17px;
  left: 18px;
  transform: rotate(-45deg);
  margin: 0;
}

@media (max-width: 1200px) {
  .header__wrapper.container {
    max-width: 100%;
  }

  .menu__item--has-children:before {
    content: "";
    position: absolute;
    top: 20px;
    left: 35px;
    display: block;
    width: 12px;
    height: 12px;
    border-left: 2px solid #BC8915;
    border-bottom: 2px solid #BC8915;
    transform: rotate(-45deg);
  }

  .header__phone {
    margin-left: auto;
  }

  .nav-toggle {
    display: block;
  }

  .nav {
    position: absolute;
    left: 0;
    top: 84px;
    background: #fff;
    width: 100%;
    display: none;
    padding: 20px 0;
  }
  .nav > ul > li > a {
    font-size: 24px;
    font-weight: 600;
  }
  .nav ul {
    display: block;
  }
  .nav ul li {
    width: 100%;
  }
  .nav ul li a {
    color: #BC8915;
    width: 100%;
    padding-left: 70px;
    padding-right: 20px;
    font-weight: 500;
  }
  .nav ul ul {
    width: 100%;
    position: relative;
  }
  .nav ul ul a {
    font-size: 20px;
    padding: 10px 10px 10px 50px;
  }
}
@media (max-width: 992px) {
  .subpage .header__logo {
    top: 10px;
    left: 100px;
  }
  .subpage .header__logo img {
    height: 88px;
  }
  .subpage .header__wrapper {
    padding-left: 20px;
  }
}
@media (max-width: 576px) {
  .nav {
    height: calc(100vh - 85px);
    overflow: auto;
  }
  .nav > ul {
    padding-bottom: 50px;
  }

  .header__phone {
    text-transform: uppercase;
  }

  .header .link__reservation {
    margin-right: 10px;
    margin-left: 5px;
    border: none;
  }

  .nav-toggle {
    margin-left: 0;
  }
}
.breadcrumbs {
  padding-top: 160px;
}
.breadcrumbs ul {
  display: flex;
}
.breadcrumbs ul span,
.breadcrumbs ul a {
  font-size: 11px;
  color: #324050;
  display: inline-block;
  text-transform: uppercase;
  padding: 5px 0;
  line-height: 1;
  font-weight: 700;
}
.breadcrumbs ul a {
  margin-right: 10px;
}
.breadcrumbs ul a:after {
  margin-left: 10px;
  color: #324050;
  content: "/";
}
.breadcrumbs ul a:focus, .breadcrumbs ul a:hover {
  outline: none;
  color: #facc5a;
}
.breadcrumbs ul a:focus:after, .breadcrumbs ul a:hover:after {
  color: #324050;
}

.overflow-hidden {
  overflow: hidden;
}

.swiper-intro {
  max-width: 100%;
  overflow: hidden;
  width: 100%;
  min-height: 800px;
  height: 100vh;
}
.swiper-intro .swiper-slide {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #fff;
}

.intro__subpage {
  max-width: 100%;
  overflow: hidden;
  width: 100%;
  min-height: 800px;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
}
.intro__subpage .container--half {
  transform: translateX(-50%);
  padding-right: 150px;
  position: relative;
  z-index: 10;
}
.intro__subpage .title--big,
.intro__subpage p {
  position: relative;
  left: -100px;
  opacity: 0;
  transition: left 0.7s ease-out 0.5s, opacity 0.7s ease-out 0.5s;
}
.intro__subpage p {
  transition-delay: 0.7s;
}
.intro__subpage .bigLetter {
  font-size: 500px;
  right: 50%;
  opacity: 0;
}
@media (max-width: 768px) {
  .intro__subpage .bigLetter {
    right: 40%;
    z-index: 5;
    top: 30%;
    font-size: 50px;
  }
}
.intro__subpage.wow-loaded .title--big,
.intro__subpage.wow-loaded p {
  left: 0;
  opacity: 1;
}
.intro__subpage.wow-loaded .bigLetter {
  font-size: 500px;
  opacity: 1;
  z-index: 20;
}
@media (max-width: 768px) {
  .intro__subpage.wow-loaded .bigLetter {
    font-size: 160px;
    z-index: 5;
  }
}

.intro__subpageImg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.intro__subpageImg:before {
  content: "";
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  left: 0;
  display: block;
  background: rgba(255, 255, 255, 0.76);
  z-index: 2;
}
.intro__subpageImg img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  -o-object-fit: cover;
     object-fit: cover;
}

.intro__img {
  position: absolute;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
  right: 50%;
  width: 100%;
  height: 100%;
}

.intro__left {
  width: 50%;
  max-width: 50%;
  color: rgba(50, 64, 80, 0.8);
}
.intro__left .container--half {
  margin-right: 0;
  padding-right: 150px;
}
.intro__left p {
  font-weight: 300;
  font-size: 20px;
  margin-bottom: 50px;
}
.intro__left h1,
.intro__left h2,
.intro__left p,
.intro__left .btn {
  transition: left 0.3s ease-out, opacity 0.7s ease-out;
}
.intro__left h1,
.intro__left h2,
.intro__left p,
.intro__left .btn {
  position: relative;
  left: -100px;
  opacity: 0;
}
@media (max-width: 768px) {
  .intro__left p {
    line-height: 1.4;
    font-size: 18px;
    margin-bottom: 30px;
  }
}

.intro__right {
  width: 50%;
  max-width: 655px;
  background: #fff;
  height: 100%;
  position: relative;
}
.intro__right img {
  width: 61%;
  max-height: 100%;
  position: absolute;
  right: -20%;
  top: 50%;
  transform: translateY(-40%);
  transition: right 0.3s ease-out, opacity 0.3s ease-out;
  opacity: 0;
}

.bigLetter {
  font-size: 300px;
  font-weight: 700;
  -webkit-text-fill-color: #0000;
  -webkit-background-clip: text;
  line-height: 1;
  top: 50%;
  right: 100%;
  transform: translate(66.6%, -50%);
  position: absolute;
  transition: all 1.4s ease-out;
  transition: font-size 1.4s ease-out, opacity 1.4s ease-out;
  opacity: 0;
  font-family: "untitled1regular";
}

.swiper-slide-active .intro__right img {
  right: 10%;
  opacity: 1;
  transition: all 1.4s ease-out 1s;
}
.swiper-slide-active .bigLetter {
  font-size: 450px;
  opacity: 1;
}
.swiper-slide-active .intro__left h1,
.swiper-slide-active .intro__left h2,
.swiper-slide-active .intro__left p {
  left: 0;
  opacity: 1;
  transition: left 1.4s ease-out, opacity 0.7s ease-out;
  transition-delay: 1s;
}
.swiper-slide-active .intro__left .btn {
  left: 0;
  opacity: 1;
  transition: left 1.4s ease-out 1s, opacity 0.7s ease-out 1s, box-shadow 0.3s ease-in-out;
}

.intro__wrapper {
  position: relative;
}

.intro__wrapper--subpage {
  height: auto;
  min-height: 58vh;
}

@media (max-width: 992px) {
  .swiper-slide-active .bigLetter {
    font-size: 500px;
  }
  .swiper-slide-active .intro__right img {
    right: 50%;
  }

  .bigLetter {
    top: 40%;
  }

  .intro__right img {
    top: 50%;
    transform: translateX(50%);
  }

  .intro__subpage .container--half {
    padding-right: 40px;
  }
}
@media (max-width: 768px) {
  .intro__subpage {
    min-height: 400px;
  }
  .intro__subpage .container--half {
    position: relative;
    height: 100%;
    transform: none;
    padding-top: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 50px;
    padding-left: 40px;
  }
  .intro__subpage .intro__subpageImg:before {
    width: 100%;
  }
}
@media (max-width: 576px) {
  .intro__subpage .go-bottom {
    left: -25px;
  }

  .intro__wrapper .swiper-intro {
    height: 800px;
  }
  .intro__wrapper .swiper-intro .swiper-slide {
    display: block;
  }
  .intro__wrapper .intro__left,
.intro__wrapper .intro__right {
    width: 100%;
    max-width: 100%;
  }
  .intro__wrapper .intro__left {
    padding-top: 120px;
    right: 0;
    height: 500px;
  }
  .intro__wrapper .intro__left .container--half {
    padding-right: 40px;
    position: relative;
    height: 100%;
  }
  .intro__wrapper .intro__left .btn {
    position: absolute;
    bottom: 30px;
  }
  .intro__wrapper .intro__right {
    height: 300px;
  }
  .intro__wrapper .intro__right img {
    transform: translate(94%, -40%);
    max-width: 40%;
  }
  .intro__wrapper .intro__img {
    right: 0;
  }
  .intro__wrapper .bigLetter {
    top: 50%;
    font-size: 100px;
    transform: translateY(-50%);
    right: initial;
    left: 30px;
  }
  .intro__wrapper .swiper-slide-active .bigLetter {
    font-size: 200px;
  }
  .intro__wrapper .swiper-slide-active .btn {
    left: 20px;
  }
  .intro__wrapper .swiper-button-prev {
    left: initial;
    right: 100px;
    bottom: 300px;
  }
  .intro__wrapper .swiper-button-next {
    left: initial;
    right: 20px;
    bottom: 300px;
  }
  .intro__wrapper .swiper-pagination-fraction {
    bottom: 410px;
    left: initial;
    right: 38px;
    transform: rotate(-90deg) !important;
  }
}
.special__left {
  position: relative;
  width: 100%;
}
.special__left svg {
  position: absolute;
  right: 0;
}

@-webkit-keyframes specialImg {
  0% {
    right: -80px;
  }
  50% {
    right: -100px;
  }
  100% {
    right: -80px;
  }
}

@keyframes specialImg {
  0% {
    right: -80px;
  }
  50% {
    right: -100px;
  }
  100% {
    right: -80px;
  }
}
@-webkit-keyframes specialImgMobile {
  0% {
    right: 20px;
  }
  50% {
    right: 0px;
  }
  100% {
    right: 20px;
  }
}
@keyframes specialImgMobile {
  0% {
    right: 20px;
  }
  50% {
    right: 0px;
  }
  100% {
    right: 20px;
  }
}
@-webkit-keyframes specialImgSmall {
  0% {
    left: 0px;
  }
  50% {
    left: 20px;
  }
  100% {
    left: 0px;
  }
}
@keyframes specialImgSmall {
  0% {
    left: 0px;
  }
  50% {
    left: 20px;
  }
  100% {
    left: 0px;
  }
}
.swiper-special {
  max-width: 100%;
  width: 100%;
  height: 810px;
  margin-bottom: 20px;
}
.swiper-special img {
  position: absolute;
  right: -80px;
}
.swiper-special-thumbs {
  position: absolute;
  top: 50%;
  z-index: 20;
  right: -70px;
  transform: translateY(-50%);
}
.swiper-special-thumbs .swiper-wrapper {
  display: block;
  margin-left: 0;
}
.swiper-special-thumbs .swiper-slide {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;
  cursor: pointer;
}
@media (hover: hover) {
  .swiper-special-thumbs .swiper-slide:hover span {
    -webkit-text-stroke: 1px rgba(255, 255, 255, 0.6);
  }
  .swiper-special-thumbs .swiper-slide:hover img {
    border-color: rgba(255, 255, 255, 0.37);
  }
}
.swiper-special-thumbs .swiper-slide.swiper-slide-thumb-active span,
.swiper-special-thumbs .swiper-slide.swiper-slide-thumb-active img {
  position: relative;
}
.swiper-special-thumbs span {
  -webkit-text-fill-color: #0000;
  font-weight: 700;
  font-family: freight-big-pro, serif;
  font-size: 70px;
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.37);
  line-height: 1;
  transition: all 0.7s ease-out;
}
.swiper-special-thumbs .swiper-slide:not(.swiper-slide-thumb-active) span {
  background-image: none !important;
}
.swiper-special-thumbs img {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  border: 3px solid transparent;
  transition: all 0.7s ease-out;
}
.swiper-special-thumbs .swiper-slide-thumb-active span {
  -webkit-background-clip: text;
}
.swiper-special-thumbs .swiper-slide-thumb-active img {
  border-color: #E8E9E5;
}

.special__right {
  background: #f9f8f4;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding-top: 50px;
  padding-bottom: 50px;
}
.special__right .title span {
  left: 170px;
}
@media (max-width: 992px) {
  .special__right .title span {
    left: 120px;
  }
}

.txt-box p {
  font-weight: 300;
  font-size: 16px;
  margin-bottom: 30px;
  line-height: 1.6;
}
@media (max-width: 576px) {
  .txt-box p {
    font-size: 14px;
    line-height: 1.4;
  }
}

.special {
  position: relative;
  overflow: hidden;
}
.special .swiper-special-thumbs .swiper-slide {
  opacity: 0;
  transition: opacity 1s ease-out;
}
.special .swiper-special-thumbs .swiper-slide:first-child {
  transition-delay: 0.2s;
}
.special .swiper-special-thumbs .swiper-slide:nth-child(2) {
  transition-delay: 0.5s;
}
.special .swiper-special-thumbs .swiper-slide:nth-child(3) {
  transition-delay: 0.8s;
}
.special .txt-box h2,
.special .txt-box p,
.special .txt-box .btn {
  position: relative;
  opacity: 0;
  left: -100px;
  transition: left 0.7s ease-out, opacity 0.7s ease-out;
}
.special .txt-box h2 {
  transition-delay: 0.2s;
}
.special .txt-box p {
  transition-delay: 0.4s;
}
.special .txt-box .btn {
  transition-delay: 0.6s;
}
.special.wow-loaded .swiper-special-thumbs .swiper-slide {
  opacity: 1;
}
.special.wow-loaded .txt-box > * {
  opacity: 1;
  left: 0;
}
@media (min-width: 992px) {
  .special {
    display: flex;
  }
  .special .special__left {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .special .special__right .container--half {
    margin-left: 0;
    padding-left: 110px;
  }
}
@media (max-width: 992px) {
  .special .special__left {
    height: 0;
    padding-top: 120%;
    position: relative;
    background: linear-gradient(transparent 0%, transparent 80%, #f9f8f4 80%, #f9f8f4 100%);
  }
  .special .swiper-special {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .special .swiper-special img {
    height: 100%;
    width: auto;
    right: 20px;
    left: initial;
  }
  .special .swiper-special .swiper-slide-active img {
    -webkit-animation: specialImgMobile 1s ease-out;
            animation: specialImgMobile 1s ease-out;
  }
  .special .swiper-special-thumbs {
    right: 50px;
  }
}
@media (max-width: 576px) {
  .special .swiper-special-thumbs {
    right: 10px;
  }
  .special .swiper-special-thumbs span {
    font-size: 40px;
  }
  .special .swiper-special-thumbs img {
    width: 40px;
    height: 40px;
  }
}

.subpage-section {
  padding-top: 60px;
}
.subpage-section h1 {
  margin-top: 100px;
}

.link__parent {
  cursor: pointer;
}

.date {
  font-size: 12px;
  display: block;
  padding: 2px 0;
  color: #000;
}
.date:last-of-type {
  margin-bottom: 20px;
}

.article__item {
  margin-bottom: 80px;
  position: relative;
}
.article__item ul:not([class]) {
  list-style: disc;
  padding-left: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.article__item ol:not([class]) {
  list-style: decimal;
  padding-left: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.article__item p {
  padding: 10px 0;
}
@media (min-width: 768px) {
  .article__item {
    display: flex;
    margin-bottom: 50px;
  }
  .article__item .article__img {
    padding-top: 42%;
  }
  .article__item .article__txt {
    padding: 50px 50px 50px 0;
  }
  .article__item:nth-of-type(even) .article__txt {
    padding: 50px 0px 50px 50px;
  }
  .article__item:nth-of-type(even) .article__img {
    order: -1;
    text-align: right;
    margin-left: 8%;
  }
  .article__item .article__img,
.article__item .article__txt {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .article__item .article__img {
    max-width: 42%;
    flex: 0 0 42%;
  }
}
@media (min-width: 1200px) {
  .article__item .article__txt {
    padding: 50px 120px 50px 0;
  }
  .article__item:nth-of-type(even) .article__txt {
    padding: 50px 0px 50px 120px;
  }
}
.article__item.wow {
  opacity: 0;
  transition: all 0.3s ease-in-0ut;
  opacity: 1;
}
.article__item.wow:after {
  content: "";
  width: 100%;
  height: 100%;
  background: #fff;
  top: 0;
  right: 0;
  display: block;
  transition: width 0.7s ease-out 0.2s;
  z-index: 10;
  position: absolute;
}
@media (min-width: 768px) {
  .article__item.wow:after {
    transform: translateX(-50%);
    left: 50%;
    right: initial;
  }
}
.article__item.wow.wow-loaded:after {
  width: 0%;
}
@media (hover: hover) {
  .article__item.link__parent:hover .article__txt a {
    background-size: 100% 30px;
    color: #324050;
  }
  .article__item.link__parent:hover img {
    transform: translate(-50%, -50%) scale(1.05);
  }
}

.article__txt {
  padding: 0 0 30px 0;
}
@media (max-width: 576px) {
  .article__txt p {
    line-height: 1.4;
  }
}
.article__txt a {
  background: linear-gradient(to bottom, rgba(226, 195, 210, 0) 0%, rgba(226, 195, 210, 0) 81%, #e2c3d2 82%, #e2c3d2 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00e2c3d2", endColorstr="#e2c3d2", GradientType=0);
  background-position: 1px 93%;
  background-repeat: no-repeat;
  background-size: 0 40px;
  transition: background-size 0.3s ease-out;
}

.article__img {
  padding-top: 100%;
  position: relative;
  overflow: hidden;
}
.article__img img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  transition: all 0.3s ease-out;
}

.articleP {
  padding-top: 40px;
}
.articleP h1 {
  font-size: 40px;
  font-weight: 700;
  text-transform: uppercase;
}
.articleP h2 {
  margin-top: 40px;
}
.articleP p {
  margin-bottom: 25px;
}
.articleP ul:not([class]) {
  list-style: disc;
  padding-left: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.articleP ol:not([class]) {
  list-style: decimal;
  padding-left: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.articleP__txt {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.articleP2 {
  background: rgba(241, 238, 229, 0.4);
  padding-top: 60px;
  padding-bottom: 60px;
}

.submit {
  background: #faf8f3;
  padding-top: 60px;
  padding-bottom: 60px;
}
@media (max-width: 576px) {
  .submit {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.submit__wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}
.submit__wrapper .si__txt {
  max-width: 100px;
  display: inline-block;
  font-size: 14px;
  line-height: 1.2;
}
.submit__wrapper .ricon__item {
  margin-right: 20px;
}
@media (min-width: 1200px) {
  .submit__wrapper .ricon__item {
    margin-right: 50px;
  }
}
@media (max-width: 992px) {
  .submit__wrapper {
    flex-wrap: wrap;
    justify-content: center;
  }
  .submit__wrapper .submit__right {
    margin-left: initial;
    margin-top: 15px;
  }
  .submit__wrapper .ricon__item {
    margin-right: 0px;
  }
}
@media (max-width: 768px) {
  .submit__wrapper .submit__title {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
}

.swiper-img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  bottom: 50px;
  border-radius: 50%;
  transform: translateY(-25px);
  left: calc(50% - 90px);
  width: 60px;
  height: 60px;
  background: #E8E9E5;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-out;
  border: 1px solid transparent;
}
.swiper-button-next:hover,
.swiper-button-prev:hover {
  background: #E5F0F1;
}
.swiper-button-next:focus,
.swiper-button-prev:focus {
  outline: none;
  border: 1px solid #E5F0F1;
}
@media (max-height: 900px) {
  .swiper-button-next,
.swiper-button-prev {
    bottom: 15px;
  }
}

.swiper {
  position: relative;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("../img/arrow-left.svg");
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: center;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: calc(50% - 170px);
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  background-image: url("../img/arrow-right.svg");
}

.swiper-button-lock {
  display: none;
}

.offert {
  padding-top: 50px;
  padding-bottom: 50px;
  background-image: linear-gradient(to right, rgba(229, 240, 241, 0.2) 0%, rgba(229, 240, 241, 0.2) 50%, transparent 50%, transparent 100%);
}
.offert .title span {
  left: 70px;
}

.offert__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.offert__header .offert__nav {
  margin-left: auto;
  margin-right: 50px;
}
@media (max-width: 992px) {
  .offert__header {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
  }
  .offert__header .offert__nav {
    margin-left: 20px auto;
    justify-content: space-between;
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }
  .offert__header .link__reservation {
    position: absolute;
    right: 0;
    top: 0px;
  }
}

.offert__nav ul {
  display: flex;
  align-items: center;
}
.offert__nav li {
  position: relative;
  min-width: 65px;
}
@media (min-width: 768px) {
  .offert__nav li:not(:last-child):after {
    content: "";
    display: block;
    width: 4px;
    height: 4px;
    background: #BFBFBF;
    border-radius: 50%;
    position: absolute;
    right: -2px;
    top: 16px;
  }
}
.offert__nav button {
  background: none;
  border: none;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  padding: 12px 20px;
  text-align: left;
  color: #324050;
}
.offert__nav button img {
  position: absolute;
  left: 7px;
  top: 0px;
  z-index: -1;
  transition: transform 0.2s ease-out, left 0.2s ease-out;
}
.offert__nav button:hover, .offert__nav button.active {
  color: #000;
}
.offert__nav button:hover img, .offert__nav button.active img {
  transform: scale(1.5);
  left: 15px;
}
.offert__nav button.noactive {
  opacity: 0.8;
}
.offert__nav.menu-active button:not(.active) {
  opacity: 0.6;
}

.offert__wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}
.offert__wrapper.io-loaded-all .offert__item .offert__link * {
  -webkit-animation-delay: 0s !important;
          animation-delay: 0s !important;
}
.offert__wrapper.io-loaded-all .offert__item .offert__link h3 {
  opacity: 1;
}
.offert__wrapper.io-loaded-all .offert__item .offert__link img {
  max-width: 90%;
}
.offert__wrapper.io-loaded-all .offert__item .offert__link img:nth-child(3) {
  max-width: 50%;
}

@-webkit-keyframes offerShow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes offerShow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes offerImgWidth {
  0% {
    max-width: 50%;
    opacity: 0;
  }
  100% {
    max-width: 90%;
    opacity: 1;
  }
}
@keyframes offerImgWidth {
  0% {
    max-width: 50%;
    opacity: 0;
  }
  100% {
    max-width: 90%;
    opacity: 1;
  }
}
@-webkit-keyframes offerImgWidth50 {
  0% {
    max-width: 50%;
    opacity: 0;
  }
  100% {
    opacity: 1;
    max-width: 50%;
  }
}
@keyframes offerImgWidth50 {
  0% {
    max-width: 50%;
    opacity: 0;
  }
  100% {
    opacity: 1;
    max-width: 50%;
  }
}
.offert__item {
  max-width: 50%;
  flex: 0 0 50%;
  margin-bottom: 20px;
  transition: max-width 0.2s ease-out, opacity 0.3s ease-out;
}
@media (min-width: 768px) {
  .offert__item {
    max-width: 33.33%;
    flex: 0 0 33.33%;
  }
}
@media (min-width: 1200px) {
  .offert__item {
    max-width: 25%;
    flex: 0 0 25%;
  }
}
.offert__item .offert__link {
  transition: opacity 0.2s ease-out;
  transition-delay: 0.2s;
}
.offert__item.offer-show h3 {
  -webkit-animation: offerShow 0.8s ease-out forwards;
          animation: offerShow 0.8s ease-out forwards;
}
.offert__item.offer-show img {
  -webkit-animation: offerImgWidth 0.8s forwards ease-out;
          animation: offerImgWidth 0.8s forwards ease-out;
}
.offert__item.offer-show img:nth-child(3) {
  -webkit-animation: offerImgWidth50 1s forwards ease-out;
          animation: offerImgWidth50 1s forwards ease-out;
}
.offert__item.offer-show:nth-child(2) * {
  -webkit-animation-delay: 0.05s !important;
          animation-delay: 0.05s !important;
}
.offert__item.offer-show:nth-child(3) * {
  -webkit-animation-delay: 0.1s !important;
          animation-delay: 0.1s !important;
}
.offert__item.offer-show:nth-child(4) * {
  -webkit-animation-delay: 0.15s !important;
          animation-delay: 0.15s !important;
}
.offert__item.offer-show:nth-child(5) * {
  -webkit-animation-delay: 0.2s !important;
          animation-delay: 0.2s !important;
}
.offert__item.offer-show:nth-child(6) * {
  -webkit-animation-delay: 0.25s !important;
          animation-delay: 0.25s !important;
}
.offert__item.offer-show:nth-child(7) * {
  -webkit-animation-delay: 0.3s !important;
          animation-delay: 0.3s !important;
}
.offert__item.offer-show:nth-child(8) * {
  -webkit-animation-delay: 0.35s !important;
          animation-delay: 0.35s !important;
}
.offert__item.offer-show:nth-child(9) * {
  -webkit-animation-delay: 0.4s !important;
          animation-delay: 0.4s !important;
}
.offert__item.offer-show:nth-child(10) * {
  -webkit-animation-delay: 0.45s !important;
          animation-delay: 0.45s !important;
}
.offert__item.offer-show:nth-child(11) * {
  -webkit-animation-delay: 0.5s !important;
          animation-delay: 0.5s !important;
}
.offert__item.offer-show:nth-child(12) * {
  -webkit-animation-delay: 0.55s !important;
          animation-delay: 0.55s !important;
}
.offert__item.offer-hide {
  opacity: 0.2;
}
.offert__item.offer-hide img {
  filter: grayscale(1);
}

@-webkit-keyframes rotateLeft {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes rotateLeft {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@-webkit-keyframes rotateRight {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}
@keyframes rotateRight {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}
.offert__link {
  padding-left: 10px;
  padding-right: 10px;
  display: block;
  text-align: center;
}
.offert__link figure {
  width: 100%;
  padding-top: 100%;
  height: 0;
  position: relative;
}
.offert__link img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 0;
  transition: all 0.3s ease-out;
  transform-origin: 50% 50%;
}
.offert__link h3 {
  font-size: 16px;
  font-weight: 400;
  color: rgba(50, 64, 80, 0.8);
  line-height: 1.2;
  margin-bottom: 0;
  font-family: "Rubik", sans-serif;
  opacity: 0;
}
.offert__link:hover img:first-child {
  transform: translate(-50%, -50%) skewX(-10deg) rotate(-30deg) scale(1.1);
}
.offert__link:hover img:nth-child(2) {
  transform: translate(-50%, -50%) skewX(10deg) rotate(-30deg) scale(1.1);
}
.offert__link:hover img:nth-child(3) {
  transform: translate(-50%, -50%) scale(1.1);
}

.recommended .intro__img {
  right: 0;
  opacity: 0.5;
  transition: opacity 2s ease-out;
}
.recommended .intro__left p {
  font-size: 16px;
}
.recommended.wow-loaded .swiper-slide-active .intro__img {
  opacity: 1;
}
.recommended.wow-loaded .swiper-slide-active .recommended__right img {
  -webkit-animation: recImg 5s linear;
          animation: recImg 5s linear;
}

.swiper-recommended {
  max-width: 100%;
  overflow: hidden;
  width: 100%;
  height: 80vh;
  min-height: 600px;
}
.swiper-recommended .swiper-slide {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #fff;
}
.swiper-recommended .swiper-button-prev {
  left: calc(50% - 70px);
}
.swiper-recommended .swiper-button-next {
  left: calc(50% + 10px);
}
.swiper-recommended .swiper-pagination {
  transform: rotate(-90deg) translateY(-50px);
}
.swiper-recommended .container--half {
  padding-right: 0;
}
.swiper-recommended p {
  max-width: 80%;
}

.txt-slide {
  font-size: 25px;
  font-weight: bold;
  font-family: "Rubik", sans-serif;
  color: #324050;
  background-color: #E2C3D2;
  padding: 15px 20px;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 30px;
  line-height: 1.2;
  left: 0;
  opacity: 0;
  transition: all 0.5s ease-out 1.2s;
}
@media (min-width: 992px) {
  .txt-slide {
    padding: 20px 30px;
  }
}
@media (min-width: 1200px) {
  .txt-slide {
    font-size: 30px;
    position: relative;
    width: calc(100% + 30px);
  }
}

.swiper-slide-active .txt-slide {
  left: 0;
  opacity: 1;
}
@media (min-width: 1200px) {
  .swiper-slide-active .txt-slide {
    left: -30px;
  }
}

@-webkit-keyframes recImg {
  0% {
    opacity: 0.1;
    transform: translate(-50%, -50%) scale(1);
  }
  10% {
    opacity: 0.9;
  }
  70% {
    opacity: 1;
  }
  90% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.1;
    transform: translate(-50%, -50%) scale(1.1);
  }
}

@keyframes recImg {
  0% {
    opacity: 0.1;
    transform: translate(-50%, -50%) scale(1);
  }
  10% {
    opacity: 0.9;
  }
  70% {
    opacity: 1;
  }
  90% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.1;
    transform: translate(-50%, -50%) scale(1.1);
  }
}
.recommended__right {
  max-width: 50%;
  flex: 0 0 50%;
  position: relative;
  height: 100%;
  overflow: hidden;
}
.recommended__right img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transform: translate(-50%, -50%) scale(1);
  transition: all 0.9s ease-out;
  opacity: 0;
}

@media (max-width: 576px) {
  .recommended .swiper {
    min-height: 710px;
  }
  .recommended .swiper-slide {
    flex-wrap: wrap;
  }
  .recommended .intro__left,
.recommended .recommended__right {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .recommended .intro__left {
    height: calc(100% - 205px);
    padding-top: 70px;
  }
  .recommended .intro__left p {
    margin-bottom: 25px;
    font-size: 18px;
  }
  .recommended .container--half {
    position: relative;
    z-index: 10;
    height: 100%;
  }
  .recommended .intro__img {
    right: 0;
  }
  .recommended .btn {
    position: absolute;
    bottom: 30px;
  }
  .recommended .swiper-slide-active .btn {
    left: 20px;
  }
  .recommended .recommended__right {
    height: 205px;
  }
  .recommended .recommended__right img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .recommended .swiper-button-prev {
    left: initial;
    right: 100px;
    bottom: 200px;
  }
  .recommended .swiper-button-next {
    left: initial;
    right: 20px;
    bottom: 200px;
  }
  .recommended .swiper-pagination-fraction {
    bottom: 310px;
    left: initial;
    right: 38px;
    transform: rotate(-90deg) !important;
  }
}
@media (max-width: 992px) {
  .team {
    overflow: hidden;
  }
}
.team h3 {
  font-size: 38px;
  margin-bottom: 10px;
  line-height: 1;
  color: #000;
}
.team h4 {
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1.1;
}
@media (max-width: 1200px) {
  .team h3 {
    font-size: 34px;
  }
}
@media (max-width: 576px) {
  .team h2:not([class]),
.team h3:not([class]) {
    text-align: center;
  }
}

.team__close {
  display: block;
  position: absolute !important;
  top: 10px;
  right: -100%;
  width: 30px;
  height: 30px;
  padding: 0;
  background: none;
  border: none;
  opacity: 0;
  z-index: -10000;
  margin-right: 10px;
  transition: all 0.1s ease-out;
  border-radius: 50%;
  color: #324050;
}
.team__close:before {
  content: "+";
  font-size: 36px;
  display: block;
  position: absolute;
  transform: rotate(45deg);
  top: -4px;
  right: 3px;
}
@media (hover: hover) {
  .team__close:hover {
    color: rgba(50, 64, 80, 0.8);
  }
}
@media (max-width: 576px) {
  .team__close {
    right: 0;
  }
}

.team__wrapper {
  padding-top: 90px;
}
.team__wrapper.wow-team .team__item {
  left: -50px;
  opacity: 0;
}
.team__wrapper.wow-loaded .team__item {
  left: 0;
  z-index: 2;
  opacity: 1;
}
@media (min-width: 992px) {
  .team__wrapper {
    display: flex;
  }
  .team__wrapper .team__left {
    order: -1;
  }
  .team__wrapper .team__right {
    padding-left: 40px;
  }
  .team__wrapper .team__right,
.team__wrapper .team__left {
    width: 50%;
    flex: 0 0 50%;
  }
}
@media (min-width: 1200px) {
  .team__wrapper .team__right {
    padding-left: 70px;
    padding-bottom: 80px;
  }
}
@media (max-width: 992px) {
  .team__wrapper .team__right:before {
    left: -200px;
  }
}

.team__left {
  display: flex;
  flex-wrap: wrap;
}
.team__left .team__item {
  max-width: 50%;
  flex: 0 0 50%;
}
@media (max-width: 576px) {
  .team__left .team__item {
    padding-bottom: 0;
    overflow: hidden;
    max-width: 100%;
    flex: 0 0 100%;
  }
}

.circle {
  border-radius: 50%;
}

.team__item {
  padding: 45px 30px;
  position: relative;
  z-index: 2;
  cursor: pointer;
  transition: left 0.7s ease-out, opacity 1.5s ease-out;
}
.team__item:first-child {
  transition-delay: 0.5s;
}
.team__item:nth-child(2) {
  transition-delay: 0.6s;
}
.team__item:nth-child(3) {
  transition-delay: 0.7s;
}
.team__item:nth-child(4) {
  transition-delay: 0.8s;
}
.team__item:nth-child(5) {
  transition-delay: 0.9s;
}
.team__item:nth-child(6) {
  transition-delay: 1s;
}
.team__item:nth-child(7) {
  transition-delay: 1.1s;
}
.team__item:nth-child(8) {
  transition-delay: 1.2s;
}
.team__item:nth-child(9) {
  transition-delay: 1.3s;
}
.team__item:nth-child(10) {
  transition-delay: 1.4s;
}
.team__item > * {
  position: relative;
  z-index: 5;
}
.team__item:after {
  content: "";
  width: 0;
  height: 100%;
  display: block;
  background: #E5F0F1;
  position: absolute;
  top: 0;
  transition: width 0.2s ease-out;
}
@media (hover: hover) {
  .team__item:hover:after {
    width: 100%;
  }
  .team__item:hover img:not(.circle) {
    transform: translate(-50%, -50%) rotate(45deg);
  }
}
.team__item:nth-child(even):after {
  right: 0;
}
.team__item:nth-child(even) .team__item-right {
  right: 100%;
  padding: 30px 0px 30px 25px;
}
@media (min-width: 576px) {
  .team__item:nth-child(even) .team__close {
    left: -100%;
    right: initial;
    margin-right: 0;
    margin-left: 10px;
  }
}
.team__item:nth-child(odd):after {
  left: 0;
}
.team__item:nth-child(odd) .team__item-right {
  left: 100%;
  text-align: right;
  padding: 30px 25px 30px 0px;
}
.team__item figure {
  height: 0;
  padding-top: 100%;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  z-index: 10;
}
.team__item figure img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
}
.team__item figure img:not(.circle) {
  max-width: 120%;
  transition: transform 0.4s ease-out;
}
@media (max-width: 576px) {
  .team__item h4 {
    margin-bottom: 20px;
  }
  .team__item:after {
    content: none;
  }
  .team__item .team__item-right {
    position: static;
    opacity: 1;
    padding: 0px !important;
    text-align: left !important;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out !important;
    transition-delay: 0 !important;
  }
  .team__item figure {
    padding-top: 80%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 1400px) {
  .team__item:nth-child(even) .team__item-right {
    padding: 35px 0px 20px 15px;
    right: calc(100% - 15px);
  }
  .team__item:nth-child(odd) .team__item-right {
    padding: 35px 15px 20px 0px;
    left: calc(100% - 15px);
  }
}

.team__item-right {
  font-size: 14px;
  line-height: 1.5;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
  position: absolute;
  z-index: -1;
  top: 0;
  opacity: 0;
  width: 0;
}
.team__item-right p {
  font-size: 14px;
  line-height: 1.5;
}
.team__item-right p:not(:last-child) {
  margin-bottom: 10px;
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .team__item-right {
    font-size: 12px;
  }
  .team__item-right p {
    font-size: 12px;
  }
}
@media (min-width: 576px) and (max-width: 768px), (min-width: 992px) and (max-width: 1200px) {
  .team__item-right {
    font-size: 11px;
  }
  .team__item-right p {
    font-size: 11px;
  }
}

.team__item.open {
  z-index: 10 !important;
  background: #E5F0F1;
}
.team__item.open:after {
  width: 200%;
}
.team__item.open .team__close {
  opacity: 1;
  z-index: 20;
  transition: all 0.3s ease-out;
  transition-delay: 0.2s;
}
.team__item.open figure img:not(.circle) {
  transform: translate(-50%, -50%) rotate(45deg);
  transform-origin: 50% 50%;
}
.team__item.open .team__item-right {
  opacity: 1;
  z-index: 10;
  width: 100%;
  transition: opacity 0.2s ease-out;
  transition-delay: 0.2s;
}
@media (max-width: 1400px) {
  .team__item.open .team__item-right {
    width: calc(100% + 15px);
  }
}
@media (max-width: 576px) {
  .team__item.open .team__item-right {
    max-height: 500px;
    display: block;
    width: 100%;
    left: 0;
    padding-bottom: 30px !important;
  }
}

.team__right {
  position: relative;
  padding-bottom: 50px;
  z-index: 10;
}
@media (max-width: 576px) {
  .team__right p {
    font-size: 14px;
    line-height: 1.4;
  }
}

@media (min-width: 992px) {
  .team__right-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 80px;
  }
  .team__right-sticky:before {
    content: "";
    display: block;
    width: calc(50vw - 10px);
    height: calc(100% + 120px);
    background: #f9f8f4;
    position: absolute;
    top: -90px;
    left: -70px;
    z-index: -1;
  }
}

.team__item-right {
  position: absolute;
  top: 0;
}

.team__manager {
  margin-bottom: 30px;
}
.team__manager figure {
  height: 300px;
  position: relative;
  min-width: 300px;
  max-width: 300px;
  z-index: 10;
}
.team__manager figure img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
}
.team__manager figure img:not(.circle) {
  max-width: 120%;
  transition: transform 0.4s ease-out;
}
@media (max-width: 1400px) {
  .team__manager figure {
    min-width: 200px;
    max-width: 200px;
    height: 200px;
  }
}
.team__manager a {
  color: #BC8915;
}
@media (min-width: 576px) {
  .team__manager {
    display: flex;
    align-items: center;
    gap: 30px;
  }
}
@media (max-width: 576px) {
  .team__manager {
    text-align: center;
  }
  .team__manager figure {
    margin: 0 auto 20px auto;
  }
}

.team__quote {
  font-weight: 300;
  font-size: 34px;
  font-family: freight-big-pro, serif;
  margin-bottom: 70px;
  margin-top: 70px;
}
@media (max-width: 576px) {
  .team__quote {
    font-size: 30px;
    margin-bottom: 50px;
    margin-top: 50px;
  }
}

.insta__item--1 {
  background: #f9f8f4;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.insta__item--1 .title {
  text-align: center;
  margin-bottom: 0;
}
.insta__item--1 .title span {
  left: 0;
}

.insta__links {
  display: flex;
  gap: 40px;
}

.insta__list {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  margin-bottom: 5px;
}
@media (max-width: 540px) {
  .insta__list {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "item1 item1" "item2 item3";
  }
  .insta__list .insta__item--1 {
    grid-area: item1;
    height: 150px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .insta__list li:nth-child(2) {
    grid-area: item2;
  }
  .insta__list li:nth-child(3) {
    grid-area: item3;
  }
  .insta__list .title {
    font-size: 52px;
  }
}

.insta__list2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 5px;
}
@media (max-width: 540px) {
  .insta__list2 {
    grid-template-columns: 1fr 1fr;
  }
}

@-webkit-keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@-webkit-keyframes moveLeftRight {
  0% {
    left: 47%;
  }
  25% {
    left: 50%;
  }
  50% {
    left: 53%;
  }
  75% {
    left: 50%;
  }
  100% {
    left: 47%;
  }
}
@keyframes moveLeftRight {
  0% {
    left: 47%;
  }
  25% {
    left: 50%;
  }
  50% {
    left: 53%;
  }
  75% {
    left: 50%;
  }
  100% {
    left: 47%;
  }
}
.insta__link {
  width: 100%;
  height: 0;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
  display: block;
}
.insta__link img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease-out;
  z-index: 5;
}
.insta__link:before {
  content: url("../img/blur-4.svg");
  z-index: 10;
  top: 50%;
  left: 50%;
  position: absolute;
  transition: all 0.3s ease-out;
  opacity: 0;
  -webkit-animation: rotate 20s linear infinite;
          animation: rotate 20s linear infinite;
}
.insta__link:after {
  content: url("../img/arrow-right-btn.svg");
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 12;
  position: absolute;
  opacity: 0;
}
@media (hover: hover) {
  .insta__link:hover:before {
    opacity: 0.5;
  }
  .insta__link:hover:after {
    opacity: 1;
  }
  .insta__link:hover img {
    transform: translate(-50%, -50%) scale(1.1);
  }
}
.insta__link:focus-visible:before {
  opacity: 0.5;
}
.insta__link:focus-visible:after {
  opacity: 1;
}
.insta__link:focus-visible img {
  transform: translate(-50%, -50%) scale(1.1);
}

.insta.wow .insta__link img {
  width: 0;
  height: 0;
  transition: all 0.5s ease-out;
  opacity: 0;
}
.insta.wow.wow-loaded .insta__link img {
  width: 100%;
  height: 100%;
  opacity: 1;
}

.fslightbox-container {
  background: rgba(255, 255, 255, 0.86) !important;
}

.fslightbox-slide-btn,
.fslightbox-toolbar {
  background: #BC8915;
}
.fslightbox-slide-btn *,
.fslightbox-toolbar * {
  fill: #fff;
}

.header__2col {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 20px;
  flex-wrap: wrap;
  padding: 10px 0;
}
.header__2col * {
  margin-bottom: 0;
}

.accordion {
  border-top: 1px solid #E8E9E5;
}
.accordion .accordion__content {
  line-height: 2;
  overflow: hidden;
  margin-top: 0;
  transition: height 0.3s ease-out;
}

.accordion__panel {
  border-bottom: 1px solid #E8E9E5;
}

.accordion__heading {
  margin-bottom: 0;
}

.accrdion__btn {
  width: 100%;
  display: block;
  position: relative;
  padding: 30px 30px 30px 0;
  background: none;
  border: none;
  font-size: 34px;
  line-height: 1.2;
  font-family: freight-big-pro, serif;
  text-align: left;
  color: #324050;
}
.accrdion__btn.active:after {
  transform: translateY(-50%) rotate(0deg);
}
.accrdion__btn:before {
  content: "";
  display: block;
  width: 18px;
  height: 2px;
  transform: translateY(-50%);
  position: absolute;
  right: 4px;
  top: 50%;
  background: rgba(50, 64, 80, 0.8);
}
.accrdion__btn:after {
  content: "";
  display: block;
  width: 18px;
  height: 2px;
  transform: translateY(-50%) rotate(-90deg);
  position: absolute;
  right: 4px;
  top: 50%;
  background: rgba(50, 64, 80, 0.8);
  transition: transform 0.3s ease-out;
}
@media (max-width: 992px) {
  .accrdion__btn {
    font-size: 30px;
  }
}
@media (max-width: 576px) {
  .accrdion__btn {
    font-size: 24px;
    padding: 20px 30px 20px 0;
  }
}

.table__wrapper {
  background: rgba(241, 238, 229, 0.41);
  line-height: 1.1;
}
@media (max-width: 576px) {
  .table__wrapper {
    overflow: auto;
  }
  .table__wrapper table {
    min-width: 500px;
  }
}
.table__wrapper table {
  border-collapse: collapse;
  width: 100%;
}
.table__wrapper th {
  font-weight: 700;
  font-size: 14px;
}
.table__wrapper tr:last-child th,
.table__wrapper tr:last-child td {
  border-bottom: none;
}
.table__wrapper th,
.table__wrapper td {
  border-bottom: 1px solid #E8E9E5;
  padding: 15px 30px;
  line-height: 1.2;
  vertical-align: middle;
}
.table__wrapper th:not(:first-child),
.table__wrapper td:not(:first-child) {
  text-align: right;
}
@media (max-width: 720px) {
  .table__wrapper th,
.table__wrapper td {
    padding: 10px 20px;
    font-size: 14px;
  }
}
.table__wrapper th {
  padding-top: 10px;
  padding-bottom: 10px;
}

.priceList {
  padding-bottom: 50px;
}
.priceList th:nth-child(2),
.priceList td:nth-child(2) {
  width: 100px;
  padding-left: 5px;
  padding-right: 5px;
}
.priceList th:nth-child(3),
.priceList td:nth-child(3) {
  width: 180px;
}
@media (max-width: 720px) {
  .priceList .accordion {
    margin-left: -20px;
    margin-right: -20px;
  }
  .priceList .accordion__heading {
    padding-left: 20px;
    padding-right: 20px;
  }
  .priceList th:first-child,
.priceList td:first-child {
    padding-right: 10px;
    width: 100%;
  }
  .priceList th:nth-child(2),
.priceList td:nth-child(2) {
    white-space: nowrap;
  }
  .priceList th:last-child,
.priceList td:last-child {
    padding-left: 10px;
  }
  .priceList .table__wrapper {
    overflow: visible;
  }
  .priceList .table__wrapper table {
    min-width: 100%;
  }
  .priceList .table__wrapper th,
.priceList .table__wrapper td {
    width: auto;
    font-size: 12px;
  }
  .priceList .table__wrapper th {
    font-size: 10px;
  }
}

.tabs__nav-trigger {
  border: none;
  background: none;
  font-size: 21px;
  font-family: freight-big-pro, serif;
  font-weight: 300;
  padding: 12px 0;
  line-height: 1.2;
  text-align: left;
  color: #000;
  padding-left: 30px;
  position: relative;
}
.tabs__nav-trigger span {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  margin-right: 3px;
  position: absolute;
  right: calc(100% - 25px);
  top: 16px;
}
.tabs__nav-trigger[aria-selected=true], .tabs__nav-trigger:hover {
  color: #BC8915;
}
.tabs__nav-trigger[aria-selected=true] span, .tabs__nav-trigger:hover span {
  color: #BC8915;
}

.tabs__panel {
  left: -1000px;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-out;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;
}
.tabs__panel.is-current {
  left: 0;
}

.treatments__right {
  width: 100%;
}

.tabs__panels {
  overflow: hidden;
  width: 100%;
  position: relative;
  min-height: 550px;
}

.treatments__wrapper {
  padding-top: 30px;
  padding-bottom: 30px;
}
@media (min-width: 768px) {
  .treatments__wrapper {
    display: flex;
    gap: 40px;
  }
  .treatments__wrapper .treatments__left {
    max-width: 200px;
    flex: 0 0 200px;
    border-right: 1px solid #E5F0F1;
    padding-right: 30px;
  }
}
@media (min-width: 992px) {
  .treatments__wrapper {
    gap: 110px;
  }
  .treatments__wrapper .treatments__left {
    max-width: 340px;
    flex: 0 0 340px;
    border-right: 1px solid rgba(50, 64, 80, 0.2);
    padding-right: 30px;
  }
}

.treatments__left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
  overflow: auto;
}
@media (max-width: 768px) {
  .treatments__left {
    padding-bottom: 5px;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 3px;
    padding-right: 20px;
    margin-left: -15px;
  }
  .treatments__left li {
    padding-bottom: 5px;
  }
  .treatments__left button {
    white-space: nowrap;
    padding: 12px 15px;
    border-bottom: 2px solid rgba(50, 64, 80, 0.2);
  }
  .treatments__left button[aria-selected=true], .treatments__left button:hover {
    border-color: #BC8915;
    outline: none;
  }
  .treatments__left button span {
    display: none;
  }
}

.treatments__right h3 {
  font-size: 40px;
  margin-bottom: 30px;
  font-weight: 300;
  color: #000;
}
@media (max-width: 992px) {
  .treatments__right h3 {
    font-size: 34px;
    margin-bottom: 20px;
  }
}
@media (max-width: 768px) {
  .treatments__right .tabs__panel {
    display: block;
    padding-top: 20px;
  }
}

.treatments__info ul {
  list-style: disc;
  padding-left: 20px;
}
.treatments__info ol {
  list-style: decimal;
  padding-left: 20px;
}
.treatments__info figure {
  flex: 0 0 150px;
  width: 150px;
  height: 150px;
  position: relative;
  margin-bottom: 20px;
}
.treatments__info figure img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.treatments__info p {
  margin-bottom: 30px;
  line-height: 1.6;
}
.treatments__info ol,
.treatments__info ul {
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  .treatments__info {
    display: flex;
    gap: 30px;
    max-width: 650px;
  }
}
@media (min-width: 1200px) {
  .treatments__info figure {
    flex: 0 0 200px;
    width: 200px;
    height: 200px;
  }
}
@media (max-width: 992px) {
  .treatments__info figure {
    float: right;
    margin-left: 10px;
  }
}

.product__section {
  border-bottom: 1px solid #E5F0F1;
  padding-top: 25px;
  padding-bottom: 25px;
}
.product__section h2,
.product__section h3 {
  font-size: 26px;
  margin-bottom: 20px;
}
.product__section ul,
.product__section ol {
  padding-left: 15px;
  list-style: initial;
}
@media (min-width: 576px) {
  .product__section h2,
.product__section h3 {
    font-size: 30px;
  }
}

.product__time {
  display: inline-flex;
  margin-top: 5px;
  margin-bottom: 10px;
  align-items: center;
  padding-right: 20px;
}
.product__time:before {
  content: url("../img/clock.svg");
  margin-right: 7px;
}
.product__time strong {
  color: #000;
  margin-left: 5px;
}
@media (min-width: 768px) {
  .product__time {
    padding-right: 50px;
  }
  .product__time:before {
    margin-right: 15px;
  }
}

.atraction__item {
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  .atraction__item {
    display: flex;
    align-items: center;
  }
  .atraction__item .atraction__left,
.atraction__item .atraction__right {
    width: 50%;
    flex: 0 0 50%;
  }
  .atraction__item.atraction__item--inverse .atraction__right {
    order: -1;
  }
}
@media (min-width: 1200px) {
  .atraction__item {
    margin-bottom: 100px;
  }
}

.atraction__left img {
  max-width: 100%;
}

.atraction__right {
  padding: 20px 0px;
}
@media (min-width: 768px) {
  .atraction__right {
    padding: 20px 30px;
  }
}
@media (min-width: 1400px) {
  .atraction__right {
    padding: 20px 70px;
  }
}

.offert2 {
  padding-top: 20px;
}
@media (min-width: 576px) {
  .offert2 {
    padding-top: 50px;
  }
}
@media (min-width: 992px) {
  .offert2 {
    padding-top: 100px;
  }
}
@media (min-width: 768px) {
  .offert2 h2 {
    padding-right: 330px;
  }
}

.offert2__wrapper {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .offert2__wrapper {
    flex-wrap: wrap;
  }
}

.offert2__left {
  width: 100%;
  max-width: 730px;
}

.offert2__right {
  order: -1;
  width: 100%;
}

.offert2__blue {
  background: #BC8915;
  padding: 30px;
  color: #fff;
  margin-bottom: 20px;
}
.offert2__blue .btn2 {
  width: 100%;
}

.offer2__info {
  margin-bottom: 25px;
  text-align: right;
  font-size: 14px;
}

.offer2__price {
  display: flex;
  align-items: flex-start;
  padding-bottom: 15px;
  border-bottom: 1px solid #facc5a;
  margin-bottom: 10px;
}
.offer2__price span {
  text-decoration: line-through;
  margin-left: 5px;
  font-weight: 700;
}
.offer2__price strong {
  font-size: 40px;
  font-family: freight-big-pro, serif;
  margin-left: auto;
}

.offert2__light {
  padding: 30px;
  border: 1px solid #E5F0F1;
}
.offert2__light ul {
  font-size: 14px;
  list-style: initial;
  padding-left: 15px;
  line-height: 1.2;
}
.offert2__light ul li {
  margin-bottom: 10px;
}

.border-bottom-none {
  border-bottom: none;
}

@media (min-width: 768px) {
  .offert2__sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 100px;
    margin-top: -50px;
  }
}

@media (min-width: 768px) {
  .offert2__left {
    padding-right: 50px;
    padding-left: 0;
  }

  .offert2__right {
    order: 1;
    width: 330px;
    flex: 0 0 330px;
  }
}
@media (max-width: 768px) {
  .offert2__light {
    padding: 20px;
  }
}
@media (max-width: 768px) and (min-width: 370px) {
  .offert2__left {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.parallax {
  background-attachment: scroll;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 80vh;
  min-height: 500px;
}
@media (hover: hover) {
  .parallax {
    background-attachment: fixed;
  }
}
@media (max-width: 576px) {
  .parallax {
    min-height: 350px;
    height: 50vh;
  }
}

.gallery {
  padding-top: 60px;
}

.gallery__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 2px;
}
@media (max-width: 576px) {
  .gallery__wrapper {
    grid-template-columns: 1fr 1fr;
  }
}

.gallery__link {
  height: 0;
  padding-top: 70%;
  position: relative;
  overflow: hidden;
  display: block;
}
.gallery__link img {
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  -o-object-fit: cover;
     object-fit: cover;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease-out;
  position: absolute;
  z-index: 5;
}
.gallery__link:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  background: transparent;
  z-index: 10;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease-in;
  position: absolute;
}
.gallery__link:before {
  content: url("../img/btn-search-white.svg");
  top: 50%;
  left: 50%;
  background: transparent;
  z-index: 15;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease-in;
  position: absolute;
  opacity: 0;
}
.gallery__link:hover, .gallery__link:focus {
  outline: none;
}
.gallery__link:hover img, .gallery__link:focus img {
  transform: translate(-50%, -50%) scale(1.1);
}
.gallery__link:hover:after, .gallery__link:focus:after {
  background: rgba(13, 33, 83, 0.6);
}
.gallery__link:hover:before, .gallery__link:focus:before {
  opacity: 1;
}

.system__wrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  margin-bottom: 40px;
}
@media (min-width: 720px) {
  .system__wrapper {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 50px;
  }
}
@media (min-width: 1350px) {
  .system__wrapper {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
  }
}

.system__item {
  padding: 10px 20px 40px 20px;
  border: 1px solid #E5F0F1;
}
.system__item img {
  padding: 0 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

.system__title {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  line-height: 1;
}
.system__title h3 {
  font-size: 22px;
  margin-bottom: 0;
}
.system__title span {
  margin-left: auto;
  display: block;
  padding-right: 10px;
  max-width: 100px;
  font-size: 14px;
}
.system__title strong {
  font-size: 80px;
  font-family: freight-big-pro, serif;
  color: #BC8915;
}
@media (max-width: 768px) {
  .system__title strong {
    font-size: 60px;
  }
}
@media (max-width: 576px) {
  .system__title {
    flex-wrap: wrap;
    justify-content: center;
  }
  .system__title h3 {
    width: 100%;
    text-align: center;
  }
  .system__title span {
    max-width: 70%;
    margin-left: initial;
  }
}

.quest {
  background: #faf8f3;
  padding-top: 70px;
  padding-bottom: 60px;
}

.quest__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  width: 100%;
}
.quest__wrapper .quest__left {
  width: 100%;
}
.quest__wrapper .quest__right {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
}
@media (min-width: 1400px) {
  .quest__wrapper {
    max-width: 1050px;
  }
}
.quest__wrapper a {
  white-space: nowrap;
  display: inline-block;
}
.quest__wrapper p {
  line-height: 1.4;
  font-size: 20px;
  font-weight: 600px;
}
.quest__wrapper .tel--big {
  font-weight: 700;
}
@media (max-width: 768px) {
  .quest__wrapper {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }
}

.tel--big {
  font-size: 30px;
  font-family: freight-big-pro, serif;
}

@media (max-width: 576px) {
  .daterangepicker .drp-buttons {
    text-align: center !important;
  }
  .daterangepicker .drp-selected {
    width: 100%;
    padding: 5px 10px 10px 10px;
  }
}
.links__wrapper {
  position: absolute;
  top: 115px;
  right: 20px;
}
.links__wrapper a {
  display: block;
  margin-bottom: 10px;
}
.links__wrapper a img {
  border-radius: 7px;
}
@media (max-width: 1400px) {
  .links__wrapper a img {
    max-width: 70px;
  }
}
@media (max-width: 992px) {
  .links__wrapper {
    top: 90px;
  }
  .links__wrapper a img {
    max-width: 50px;
  }
}
@media (max-width: 576px) {
  .links__wrapper .link--camera {
    display: none;
  }
}

.pagination {
  margin-top: 70px;
  padding-bottom: 60px;
}
.pagination ul {
  display: flex;
  padding: 0;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
.pagination .prev,
.pagination .next {
  position: relative;
  width: 48px;
  height: 48px;
  display: block;
  position: relative;
}
.pagination .prev:before,
.pagination .next:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: url("../img/arrow-right.svg");
}
@media (min-width: 1200px) {
  .pagination .prev,
.pagination .next {
    width: 160px;
  }
}
.pagination .prev:hover,
.pagination .next:hover {
  right: 10px;
}
.pagination .prev:before {
  content: url("../img/arrow-left.svg");
}

.pagination__item {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  border-radius: 6px;
  border: 1px solid #324050;
  color: #324050;
}
.pagination__item.active, .pagination__item:hover {
  color: #BC8915;
}

.paginationArt {
  padding-top: 50px;
  padding-bottom: 50px;
}
.paginationArt li {
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
}
.paginationArt li a {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
}
.paginationArt li:after {
  position: absolute;
  bottom: 7px;
  left: 170px;
  content: url("../img/arrow-left-btn.svg");
}
.paginationArt li:nth-child(2) h3 {
  order: -1;
  text-align: right;
}
.paginationArt li:nth-child(2):after {
  right: 170px;
  left: initial;
  content: url("../img/arrow-right-btn.svg");
}
.paginationArt h3 {
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  line-height: 1.2;
  -webkit-box-orient: vertical;
  margin-bottom: 18px;
}
.paginationArt h3 span {
  background: linear-gradient(to bottom, rgba(226, 195, 210, 0) 0%, rgba(226, 195, 210, 0) 81%, #e2c3d2 82%, #e2c3d2 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00e2c3d2", endColorstr="#e2c3d2", GradientType=0);
  background-position: 1px 93%;
  background-repeat: no-repeat;
  background-size: 0 20px;
  transition: background-size 0.3s ease-out;
}
@media (min-width: 992px) {
  .paginationArt h3 {
    font-size: 28px;
  }
}
.paginationArt img {
  width: 135px;
  height: 135px;
  -o-object-fit: cover;
     object-fit: cover;
  transition: all 0.3s ease-out;
}
@media (min-width: 576px) {
  .paginationArt {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .paginationArt ul {
    display: flex;
    gap: 30px;
    justify-content: space-between;
  }
  .paginationArt li {
    max-width: 580px;
    flex: 0 1 50%;
  }
  .paginationArt h3 {
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }
}
@media (min-width: 992px) {
  .paginationArt li a {
    gap: 40px;
  }
}
@media (hover: hover) {
  .paginationArt a:hover h3 span {
    background-size: 100% 20px;
  }
}

.bg--beauty {
  background: rgba(241, 238, 229, 0.41) !important;
}

.bg--nails, .nails .team__right:before,
.nails .table__wrapper {
  background: rgba(226, 195, 210, 0.2) !important;
}

.bg--hair, .hair .team__right:before,
.hair .table__wrapper {
  background: rgba(240, 214, 156, 0.14) !important;
}

.nails .intro__subpageImg:before {
  background: rgba(255, 250, 253, 0.86) !important;
}

.hair .intro__subpageImg:before {
  background: rgba(254, 248, 235, 0.86) !important;
}

.medicine .intro__subpageImg:before {
  background: rgba(242, 247, 251, 0.86) !important;
}

@media (min-width: 576px) {
  .only-mobile {
    display: none;
  }
}

@media (max-width: 576px) {
  .only-desktop {
    display: none;
  }
}

@media (min-width: 576px) {
  .col-sm-half {
    max-width: 50%;
    flex: 0 0 50%;
  }
}

.black-section {
  background: #061405;
  overflow: hidden;
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  .black-section .container {
    display: flex;
    align-items: flex-end;
  }
}
@media (min-width: 1400px) {
  .black-section .container {
    max-width: 1140px;
  }
}
.black-section figure {
  width: 100%;
  height: 0;
  padding-top: 100%;
  position: relative;
  text-align: right;
}
.black-section figure img {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  height: 0;
  transition: height 0.7s ease-out;
}
.black-section .black-section__txt {
  color: #fff;
  font-size: 26px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  z-index: 10;
  min-height: 350px;
}
.black-section .black-section__txt p {
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: 490px;
  font-family: freight-big-pro, serif;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.7s ease-out;
}
.black-section.wow-loaded figure img {
  height: 100%;
}
.black-section.wow-loaded .black-section__txt p {
  max-height: 400px;
}