@font-face {
    font-family: 'untitled1regular';
    src: url('../fonts/moss-webfont.woff2') format('woff2'),
        url('../fonts/moss-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@import "components/variables";
@import "components/typography.scss";
@import "components/footer.scss";
@import "components/header.scss";



.overflow-hidden {
    overflow: hidden;
}

.swiper-intro {
    max-width: 100%;
    overflow: hidden;
    width: 100%;
    min-height: 800px;
    height: 100vh;

    .swiper-slide {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: #fff;
    }
}



.intro__subpage {
    max-width: 100%;
    overflow: hidden;
    width: 100%;
    min-height: 800px;
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;

    .container--half {
        transform: translateX(-50%);
        padding-right: 150px;
        position: relative;
        z-index: 10;
        // animation:introSubpage 0.3s ease-out forwards;
    }

    .title--big,
    p {
        position: relative;
        left: -100px;
        opacity: 0;
        transition: left 0.7s ease-out 0.5s, opacity 0.7s ease-out 0.5s;
    }

    p {
        transition-delay: 0.7s;
    }

    .bigLetter {
        font-size: 500px;
        right: 50%;
        opacity: 0;

        @media(max-width:$grid-breakpoints-md) {
            right: 40%;
            z-index: 5;
            top: 30%;
            font-size: 50px;
        }
    }

    &.wow-loaded {

        .title--big,
        p {
            left: 0;
            opacity: 1;
        }

        .bigLetter {
            font-size: 500px;
            opacity: 1;
            z-index: 20;


            @media(max-width:$grid-breakpoints-md) {
                font-size: 160px;
                z-index: 5;
            }
        }

    }
}

.intro__subpageImg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    &:before {
        content: "";
        position: absolute;
        width: 50%;
        height: 100%;
        top: 0;
        left: 0;
        display: block;
        background: rgba(255, 255, 255, 0.76);
        z-index: 2;
    }

    img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        object-fit: cover;
    }
}


.intro__img {
    position: absolute;
    object-fit: cover;
    z-index: -1;
    top: 50%;
    transform: translateY(-50%);
    right: 50%;
    width: 100%;
    height: 100%;
}


.intro__left {
    width: 50%;
    max-width: 50%;
    color: $txt;

    .container--half {
        margin-right: 0;
        padding-right: 150px;
    }

    p {
        font-weight: 300;
        font-size: 20px;
        margin-bottom: 50px;

    }

    h1,
    h2,
    p,
    .btn {
        transition: left 0.3s ease-out, opacity 0.7s ease-out;
    }


    h1,
    h2,
    p,
    .btn {
        position: relative;
        left: -100px;
        opacity: 0
    }


    @media(max-width:$grid-breakpoints-md) {

        p {
            line-height: 1.4;
            font-size: 18px;
            margin-bottom: 30px;
        }
    }
}

.intro__right {
    width: 50%;
    max-width: 655px;
    background: #fff;
    height: 100%;
    position: relative;


    img {
        width: 61%;
        max-height: 100%;
        position: absolute;
        right: -20%;
        top: 50%;
        transform: translateY(-40%);
        transition: right 0.3s ease-out, opacity 0.3s ease-out;
        opacity: 0;
    }
}



.bigLetter {
    font-size: 300px;
    font-weight: 700;

    -webkit-text-fill-color: #0000;
    -webkit-background-clip: text;
    line-height: 1;
    top: 50%;
    right: 100%;
    transform: translate(66.6%, -50%);
    position: absolute;
    transition: all 1.4s ease-out;
    transition: font-size 1.4s ease-out, opacity 1.4s ease-out;
    opacity: 0;
    font-family: 'untitled1regular';

}

.swiper-slide-active {
    .intro__right {
        img {
            right: 10%;
            opacity: 1;
            transition: all 1.4s ease-out 1s;
        }
    }

    .bigLetter {
        font-size: 450px;
        opacity: 1;
    }

    .intro__left {

        h1,
        h2,
        p {
            left: 0;
            opacity: 1;
            transition: left 1.4s ease-out, opacity 0.7s ease-out;
            transition-delay: 1s;

        }

        .btn {
            left: 0;
            opacity: 1;
            transition: left 1.4s ease-out 1s, opacity 0.7s ease-out 1s, box-shadow 0.3s ease-in-out;

        }
    }
}


.intro__wrapper {
    position: relative;

}

.intro__wrapper--subpage {
    height: auto;
    min-height: 58vh;
}

@media(max-width:$grid-breakpoints-lg) {
    .swiper-slide-active {
        .bigLetter {
            font-size: 500px;
        }

        .intro__right img {
            right: 50%;

        }
    }

    .bigLetter {
        top: 40%;
    }

    .intro__right img {
        top: 50%;
        transform: translateX(50%);
    }

    .intro__subpage {
        .container--half {
            padding-right: 40px;
        }
    }
}

@media(max-width:$grid-breakpoints-md) {
    .intro__subpage {
        min-height: 400px;

        .container--half {
            position: relative;
            height: 100%;
            transform: none;
            padding-top: 90px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding-bottom: 50px;
            padding-left: 40px;
        }

        .intro__subpageImg:before {
            width: 100%;
        }
    }
}

@media(max-width:$grid-breakpoints-sm) {
    .intro__subpage {
        .go-bottom {
            left: -25px;
        }
    }

    .intro__wrapper {
        .swiper-intro {
            height: 800px;

            .swiper-slide {
                display: block;
            }
        }

        .intro__left,
        .intro__right {
            width: 100%;
            max-width: 100%;
        }

        .intro__left {
            padding-top: 120px;
            right: 0;
            height: 500px;


            .container--half {
                padding-right: 40px;
                position: relative;
                height: 100%;
            }

            .btn {
                position: absolute;
                bottom: 30px;
            }
        }

        .intro__right {
            height: 300px;

            img {
                // right: 50%;
                transform: translate(94%, -40%);
                max-width: 40%;
            }
        }

        .intro__img {
            right: 0;

        }

        .bigLetter {
            top: 50%;
            font-size: 100px;
            transform: translateY(-50%);
            right: initial;
            left: 30px;
        }

        .swiper-slide-active {
            .bigLetter {
                font-size: 200px;
            }

            .btn {
                left: 20px;
            }
        }

        .swiper-button-prev {
            left: initial;
            right: 100px;
            bottom: 300px;
        }

        .swiper-button-next {
            left: initial;
            right: 20px;
            bottom: 300px;
        }

        .swiper-pagination-fraction {
            bottom: 410px;
            left: initial;
            right: 38px;
            transform: rotate(-90deg) !important;
            ;
        }
    }

}



.special__left {
    position: relative;
    width: 100%;

    svg {
        position: absolute;
        right: 0;
    }

}


@keyframes specialImg {
    0% {
        right: -80px;
    }

    50% {
        right: -100px;
    }

    100% {
        right: -80px;
    }
}

@keyframes specialImgMobile {
    0% {
        right: 20px;
    }

    50% {
        right: 0px;
    }

    100% {
        right: 20px;
    }
}

@keyframes specialImgSmall {
    0% {
        left: 0px;
    }

    50% {
        left: 20px;
    }

    100% {
        left: 0px;
    }
}


.swiper-special {
    max-width: 100%;
    width: 100%;
    height: 810px;
    margin-bottom: 20px;

    img {
        position: absolute;
        right: -80px;
    }

    .swiper-slide-active {
        img {
            // animation: specialImg 1s ease-out;
        }
    }
}


.swiper-special-thumbs {
    position: absolute;
    top: 50%;
    z-index: 20;
    right: -70px;
    transform: translateY(-50%);

    .swiper-wrapper {
        display: block;
        margin-left: 0;
    }

    .swiper-slide {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 30px;
        cursor: pointer;

        @media(hover:hover) {
            &:hover {
                span {
                    // -webkit-text-fill-color: rgba(255, 255, 255, 0.1);
                    -webkit-text-stroke: 1px rgba(255, 255, 255, 0.6);
                }

                img {
                    border-color: rgba(255, 255, 255, 0.37);
                }
            }
        }

        &.swiper-slide-thumb-active {

            span,
            img {
                position: relative;
                // animation: specialImgSmall 1s ease-out;
            }
        }
    }


    span {
        -webkit-text-fill-color: #0000;
        font-weight: 700;
        font-family: freight-big-pro, serif;
        font-size: 70px;
        -webkit-text-stroke: 1px rgba(255, 255, 255, 0.37);
        line-height: 1;
        transition: all 0.7s ease-out;
    }

    .swiper-slide:not(.swiper-slide-thumb-active) {
        span {
            background-image: none !important;
        }
    }

    img {
        width: 60px;
        height: 60px;
        border-radius: 10px;
        border: 3px solid transparent;
        transition: all 0.7s ease-out;
    }


    .swiper-slide-thumb-active {
        span {
            -webkit-background-clip: text;
        }

        img {
            border-color: #E8E9E5;
        }
    }
}

.special__right {
    background: $bg-grey;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding-top: 50px;
    padding-bottom: 50px;

    .title {
        span {
            left: 170px;

            @media (max-width: 992px) {
                left: 120px;
            }
        }
    }
}

.txt-box {
    p {
        font-weight: 300;
        font-size: 16px;
        margin-bottom: 30px;
        line-height: 1.6;

        @media(max-width: $grid-breakpoints-sm) {
            font-size: 14px;
            line-height: 1.4;
        }
    }

}



.special {
    position: relative;
    overflow: hidden;

    .swiper-special-thumbs {
        .swiper-slide {
            // left: -1500px;
            opacity: 0;
            transition: opacity 1s ease-out;

            &:first-child {
                transition-delay: 0.2s;
            }

            &:nth-child(2) {
                transition-delay: 0.5s;
            }

            &:nth-child(3) {
                transition-delay: 0.8s;
            }
        }
    }

    .txt-box {

        // max-height: 0;
        // overflow: hidden;
        // transition: max-height 1s ease-out;
        h2,
        p,
        .btn {
            position: relative;
            opacity: 0;
            left: -100px;
            transition: left 0.7s ease-out, opacity 0.7s ease-out;

        }

        h2 {
            transition-delay: 0.2s;
        }

        p {
            transition-delay: 0.4s;
        }

        .btn {
            transition-delay: 0.6s;
        }
    }

    &.wow-loaded {
        .swiper-special-thumbs {
            .swiper-slide {
                // left: 0;
                opacity: 1;
            }
        }

        .txt-box {

            // max-height: 700px;
            >* {
                opacity: 1;
                left: 0;

            }
        }

    }

    @media(min-width:$grid-breakpoints-lg) {
        display: flex;

        .special__left {
            flex: 0 0 50%;
            max-width: 50%;
        }

        .special__right {

            .container--half {
                margin-left: 0;
                padding-left: 110px;

            }
        }
    }

    @media(max-width:$grid-breakpoints-lg) {

        .special__left {
            height: 0;
            padding-top: 120%;
            position: relative;
            background: linear-gradient(transparent 0%, transparent 80%, $bg-grey 80%, $bg-grey 100%);
        }

        .swiper-special {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;

            img {
                height: 100%;
                width: auto;
                right: 20px;
                left: initial;

            }

            .swiper-slide-active {
                img {
                    animation: specialImgMobile 1s ease-out;
                }
            }
        }

        .swiper-special-thumbs {
            right: 50px;
        }
    }

    @media(max-width:$grid-breakpoints-sm) {
        .swiper-special-thumbs {
            right: 10px;

            span {
                font-size: 40px;
            }

            img {
                width: 40px;
                height: 40px;
            }
        }
    }

}


.subpage-section {
    padding-top: 60px;

    h1 {
        margin-top: 100px;
    }
}

.link__parent {
    cursor: pointer;
}

.date {
    font-size: 12px;
    display: block;
    padding: 2px 0;
    color: $txt-7;

    &:last-of-type {
        margin-bottom: 20px;
    }

}

.article__item {

    ul:not([class]) {
        list-style: disc;
        padding-left: 20px;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    ol:not([class]) {
        list-style: decimal;
        padding-left: 20px;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    p {
        padding: 10px 0;
    }


    margin-bottom: 80px;
    position: relative;

    @media(min-width:$grid-breakpoints-md) {
        display: flex;
        margin-bottom: 50px;

        .article__img {
            padding-top: 42%;

            img {
                // width: 100%;
                // height: 100%;
                // object-fit: cover;
            }
        }

        .article__txt {
            padding: 50px 50px 50px 0;
        }

        &:nth-of-type(even) {

            .article__txt {
                padding: 50px 0px 50px 50px;
            }

            .article__img {
                order: -1;
                text-align: right;
                margin-left: 8%;
            }
        }

        .article__img,
        .article__txt {
            max-width: 50%;
            flex: 0 0 50%;
        }

        .article__img {
            max-width: 42%;
            flex: 0 0 42%;
        }
    }

    @media(min-width:$grid-breakpoints-xl) {
        .article__txt {
            padding: 50px 120px 50px 0;
        }

        &:nth-of-type(even) {
            .article__txt {
                padding: 50px 0px 50px 120px;
            }
        }
    }

    &.wow {
        opacity: 0;
        transition: all 0.3s ease-in-0ut;

        &:after {
            content: "";
            width: 100%;
            height: 100%;
            background: #fff;
            top: 0;
            right: 0;
            display: block;
            transition: width 0.7s ease-out 0.2s;
            z-index: 10;
            position: absolute;

            @media(min-width:$grid-breakpoints-md) {
                transform: translateX(-50%);
                left: 50%;
                right: initial;
            }
        }

        opacity:1;

        &.wow-loaded {
            &:after {
                width: 0%;
            }
        }
    }

    @media(hover:hover) {
        &.link__parent {
            &:hover {
                .article__txt a {
                    background-size: 100% 30px;
                    color: $txt-dark;
                }

                img {
                    transform: translate(-50%, -50%) scale(1.05);
                }
            }
        }
    }
}

.article__txt {
    padding: 0 0 30px 0;

    @media(max-width:$grid-breakpoints-sm) {
        p {
            line-height: 1.4;
        }
    }

    a {

        background: linear-gradient(to bottom, rgba(226, 195, 210, 0) 0%, rgba(226, 195, 210, 0) 81%, rgba(226, 195, 210, 1) 82%, rgba(226, 195, 210, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00e2c3d2', endColorstr='#e2c3d2', GradientType=0);
        background-position: 1px 93%;
        background-repeat: no-repeat;
        background-size: 0 40px;
        transition: background-size .3s ease-out;
    }
}

.article__img {
    padding-top: 100%;
    position: relative;
    overflow: hidden;

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;

        transition: all 0.3s ease-out;
    }
}


.articleP {
    padding-top: 40px;

    h1 {
        font-size: 40px;
        font-weight: 700;
        text-transform: uppercase;

    }

    h2 {
        margin-top: 40px;
    }

    p {
        margin-bottom: 25px;
    }

    ul:not([class]) {
        list-style: disc;
        padding-left: 20px;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    ol:not([class]) {
        list-style: decimal;
        padding-left: 20px;
        margin-bottom: 10px;
        margin-top: 10px;
    }

}

.articleP__txt {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}


.articleP2 {
    background: $main-light;
    padding-top: 60px;
    padding-bottom: 60px;
}





.submit {
    background: $yellow-light;
    padding-top: 60px;
    padding-bottom: 60px;

    @media(max-width:$grid-breakpoints-sm) {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}

.submit__wrapper {
    display: flex;
    align-items: center;
    width: 100%;

    .si__txt {
        max-width: 100px;
        display: inline-block;
        font-size: 14px;
        line-height: 1.2;
    }

    .ricon__item {
        margin-right: 20px;

        @media(min-width:$grid-breakpoints-xl) {
            margin-right: 50px;
        }
    }

    @media(max-width:$grid-breakpoints-lg) {
        flex-wrap: wrap;
        justify-content: center;

        .submit__right {
            margin-left: initial;
            margin-top: 15px;

        }

        .ricon__item {
            margin-right: 0px;
        }
    }

    @media(max-width:$grid-breakpoints-md) {
        .submit__title {
            width: 100%;
            text-align: center;
            margin-bottom: 10px;
        }
    }

}

.swiper-img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}





.swiper-button-next,
.swiper-button-prev {
    position: absolute;
    bottom: 50px;
    border-radius: 50%;
    transform: translateY(-25px);
    left: calc(50% - 90px);
    width: 60px;
    height: 60px;
    background: #E8E9E5;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-out;
    border: 1px solid transparent;

    &:hover {
        background: $blue;
    }

    &:focus {
        outline: none;
        border: 1px solid $blue;
    }

    @media(max-height:900px) {
        bottom: 15px;
    }
}

.swiper {
    position: relative;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
    opacity: .35;
    cursor: auto;
    pointer-events: none
}

.swiper-button-next:after,
.swiper-button-prev:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url("../img/arrow-left.svg");
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: center;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
    left: calc(50% - 170px);
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
    // content: 'prev'
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
    background-image: url("../img/arrow-right.svg");
}

.swiper-button-lock {
    display: none
}


.offert {
    padding-top: 50px;
    padding-bottom: 50px;

    background-image: linear-gradient(to right, $blue10 0%, $blue10 50%, transparent 50%, transparent 100%);

    .title {
        span {
            left: 70px;
        }
    }
}

.offert__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .offert__nav {
        margin-left: auto;
        margin-right: 50px;
    }

    @media(max-width: $grid-breakpoints-lg) {
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-start;



        .offert__nav {
            margin-left: 20px auto;
            justify-content: space-between;
            max-width: 550px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 30px;
        }

        .link__reservation {
            position: absolute;
            right: 0;
            top: 0px;
        }
    }


}

.offert__nav {
    ul {
        display: flex;
        align-items: center;
    }

    li {
        position: relative;
        min-width: 65px;
    }

    li:not(:last-child) {

        @media(min-width: $grid-breakpoints-md) {
            &:after {
                content: "";
                display: block;
                width: 4px;
                height: 4px;
                background: #BFBFBF;
                border-radius: 50%;
                position: absolute;
                right: -2px;
                top: 16px;
            }
        }
    }

    button {
        background: none;
        border: none;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 700;
        padding: 12px 20px;
        text-align: left;
        color: $txt-dark;



        img {
            position: absolute;
            left: 7px;
            top: 0px;
            z-index: -1;
            transition: transform 0.2s ease-out, left 0.2s ease-out;
        }

        &:hover,
        &.active {
            img {
                transform: scale(1.5);
                left: 15px;

            }

            color:#000;
        }

        &.noactive {
            opacity: 0.8;
        }
    }

    &.menu-active {
        button:not(.active) {
            opacity: 0.6;
        }
    }

}


.offert__wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;

    &.io-loaded-all {

        .offert__item .offert__link {
            * {
                animation-delay: 0s !important;
            }

            h3 {
                opacity: 1;
            }

            img {
                max-width: 90%;

                &:nth-child(3) {
                    max-width: 50%;
                }
            }
        }
    }
}

@keyframes offerShow {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes offerImgWidth {
    0% {
        max-width: 50%;
        opacity: 0;
    }

    100% {
        max-width: 90%;
        opacity: 1;
    }
}

@keyframes offerImgWidth50 {
    0% {

        max-width: 50%;
        opacity: 0;
    }

    100% {
        opacity: 1;
        max-width: 50%;
    }
}

.offert__item {
    max-width: 50%;
    flex: 0 0 50%;
    margin-bottom: 20px;
    transition: max-width 0.2s ease-out, opacity 0.3s ease-out;

    @media(min-width:$grid-breakpoints-md) {
        max-width: 33.33%;
        flex: 0 0 33.33%;
    }

    @media(min-width:$grid-breakpoints-xl) {
        max-width: 25%;
        flex: 0 0 25%;
    }

    .offert__link {
        transition: opacity 0.2s ease-out;
        transition-delay: 0.2s;
    }


    &.offer-show {

        h3 {
            animation: offerShow 0.8s ease-out forwards;
        }

        img {

            animation: offerImgWidth 0.8s forwards ease-out;

            &:nth-child(3) {
                animation: offerImgWidth50 1s forwards ease-out;
            }

        }


        &:nth-child(2) {
            * {
                animation-delay: 0.05s !important;
            }
        }

        &:nth-child(3) {
            * {
                animation-delay: 0.1s !important;
            }
        }

        &:nth-child(4) {
            * {
                animation-delay: 0.15s !important;
            }
        }

        &:nth-child(5) {
            * {
                animation-delay: 0.2s !important;
            }
        }

        &:nth-child(6) {
            * {
                animation-delay: 0.25s !important;
            }
        }

        &:nth-child(7) {
            * {
                animation-delay: 0.3s !important;
            }
        }

        &:nth-child(8) {
            * {
                animation-delay: 0.35s !important;
            }
        }

        &:nth-child(9) {
            * {
                animation-delay: 0.4s !important;
            }
        }

        &:nth-child(10) {
            * {
                animation-delay: 0.45s !important;
            }
        }

        &:nth-child(11) {
            * {
                animation-delay: 0.5s !important;
            }
        }

        &:nth-child(12) {
            * {
                animation-delay: 0.55s !important;
            }
        }
    }

    &.offer-hide {
        opacity: 0.2;

        img {
            filter: grayscale(1);
        }

        // .offert__link {
        //     opacity: 0;
        // }
    }

}

@keyframes rotateLeft {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

@keyframes rotateRight {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(-360deg);
    }
}


.offert__link {

    padding-left: 10px;
    padding-right: 10px;
    display: block;


    figure {
        width: 100%;
        padding-top: 100%;
        height: 0;
        position: relative;
    }

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 0;
        transition: all 0.3s ease-out;
        transform-origin: 50% 50%;
    }

    text-align:center;

    h3 {
        font-size: 16px;
        font-weight: 400;
        color: $txt;
        line-height: 1.2;
        margin-bottom: 0;
        font-family: "Rubik", sans-serif;
        opacity: 0;
    }

    &:hover {
        img:first-child {
            // transform: translate(-50%, -50%) rotate(-30deg);
            // animation: rotateLeft 5s linear infinite forwards;
            transform: translate(-50%, -50%) skewX(-10deg) rotate(-30deg) scale(1.1);
        }

        img:nth-child(2) {
            // transform: translate(-50%, -50%) rotate(30deg);
            // animation: rotateRight 5s linear infinite forwards;
            transform: translate(-50%, -50%) skewX(10deg) rotate(-30deg) scale(1.1);
        }

        img:nth-child(3) {
            transform: translate(-50%, -50%) scale(1.1);
        }
    }
}


.recommended {
    .intro__img {
        right: 0;
        opacity: 0.5;
        transition: opacity 2s ease-out;
    }

    .intro__left p {
        font-size: 16px;
    }

    &.wow-loaded {
        .swiper-slide-active {
            .intro__img {
                opacity: 1;
            }

            .recommended__right img {
                animation: recImg 5s linear;
            }
        }
    }
}

.swiper-recommended {
    max-width: 100%;
    overflow: hidden;
    width: 100%;
    height: 80vh;
    min-height: 600px;

    .swiper-slide {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: #fff;
    }

    .swiper-button-prev {
        left: calc(50% - 70px);
    }

    .swiper-button-next {
        left: calc(50% + 10px);
    }

    .swiper-pagination {
        transform: rotate(-90deg) translateY(-50px)
    }

    .container--half {
        padding-right: 0;
    }

    p {
        max-width: 80%;
    }
}

.txt-slide {
    font-size: 25px;
    font-weight: bold;
    font-family: "Rubik", sans-serif;
    color: #324050;
    background-color: $pink;
    padding: 15px 20px;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 30px;
    line-height: 1.2;
    left: 0;
    opacity: 0;
    transition: all 0.5s ease-out 1.2s;



    @media(min-width:$grid-breakpoints-lg) {
        padding: 20px 30px;
    }

    @media(min-width:$grid-breakpoints-xl) {
        font-size: 30px;
        position: relative;
        width: calc(100% + 30px);
    }
}

.swiper-slide-active {

    .txt-slide {
        left: 0;
        opacity: 1;

        @media(min-width:$grid-breakpoints-xl) {
            left: -30px;
        }
    }
}

@keyframes recImg {
    0% {
        opacity: 0.1;
        transform: translate(-50%, -50%) scale(1);
    }

    10% {
        opacity: 0.9;

    }

    70% {
        opacity: 1;

    }

    90% {
        opacity: 0.5;

    }

    100% {
        opacity: 0.1;
        transform: translate(-50%, -50%) scale(1.1);

    }
}

.recommended__right {
    max-width: 50%;
    flex: 0 0 50%;
    position: relative;
    height: 100%;
    overflow: hidden;

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: translate(-50%, -50%) scale(1);
        transition: all 0.9s ease-out;
        opacity: 0;

    }
}



@media(max-width:$grid-breakpoints-sm) {
    .recommended {
        .swiper {
            min-height: 710px;
        }

        .swiper-slide {
            flex-wrap: wrap;
        }

        .intro__left,
        .recommended__right {
            max-width: 100%;
            flex: 0 0 100%;
        }

        .intro__left {
            height: calc(100% - 205px);
            padding-top: 70px;

            p {
                margin-bottom: 25px;
                font-size: 18px;
            }
        }

        .container--half {
            position: relative;
            z-index: 10;
            height: 100%;
        }

        .intro__img {
            right: 0;
        }

        .btn {
            position: absolute;
            bottom: 30px;
        }

        .swiper-slide-active {
            .btn {
                left: 20px;
            }
        }

        .recommended__right {
            height: 205px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .swiper-button-prev {
            left: initial;
            right: 100px;
            bottom: 200px;
        }

        .swiper-button-next {
            left: initial;
            right: 20px;
            bottom: 200px;
        }

        .swiper-pagination-fraction {
            bottom: 310px;
            left: initial;
            right: 38px;
            transform: rotate(-90deg) !important;
            ;
        }
    }
}

.team {

    // overflow: hidden;
    @media(max-width:$grid-breakpoints-lg) {
        overflow: hidden;
    }

    h3 {
        font-size: 38px;
        margin-bottom: 10px;
        line-height: 1;
        color: #000;
    }

    h4 {
        text-transform: uppercase;
        font-size: 14px;
        line-height: 1.1;
    }

    @media(max-width:$grid-breakpoints-xl) {
        h3 {
            font-size: 34px;
        }
    }

    @media(max-width:$grid-breakpoints-sm) {


        h2,
        h3 {
            &:not([class]) {
                text-align: center;
            }
        }
    }
}

.team__close {
    display: block;
    position: absolute !important;
    top: 10px;
    right: -100%;
    width: 30px;
    height: 30px;
    padding: 0;
    background: none;
    border: none;
    opacity: 0;
    z-index: -10000;
    margin-right: 10px;
    transition: all 0.1s ease-out;
    border-radius: 50%;
    color: $txt-dark;

    &:before {
        content: "+";
        font-size: 36px;
        display: block;
        position: absolute;
        transform: rotate(45deg);
        top: -4px;
        right: 3px;

    }

    @media(hover:hover) {
        &:hover {
            color: $txt;
        }
    }

    @media(max-width: $grid-breakpoints-sm) {
        right: 0;
    }

}


.team__wrapper {

    padding-top: 90px;


    &.wow-team {
        .team__item {
            left: -50px;
            opacity: 0;

        }
    }

    &.wow-loaded {
        .team__item {
            left: 0;
            z-index: 2;
            opacity: 1;
        }
    }

    @media(min-width:$grid-breakpoints-lg) {
        display: flex;
        // align-items: flex-start;

        .team__left {
            order: -1;
        }

        .team__right {
            padding-left: 40px;
        }

        .team__right,
        .team__left {
            width: 50%;
            flex: 0 0 50%;
        }
    }

    @media(min-width:$grid-breakpoints-xl) {
        .team__right {
            padding-left: 70px;
            padding-bottom: 80px;
        }
    }

    @media(max-width:$grid-breakpoints-lg) {
        .team__right:before {
            left: -200px;
        }
    }
}

.team__left {
    display: flex;
    flex-wrap: wrap;

    .team__item {
        max-width: 50%;
        flex: 0 0 50%;
    }

    @media(max-width:$grid-breakpoints-sm) {
        // grid-template-columns: 1fr;

        .team__item {
            padding-bottom: 0;
            overflow: hidden;
            max-width: 100%;
            flex: 0 0 100%;
        }
    }
}

.circle {
    border-radius: 50%;
}


.team__item {
    padding: 45px 30px;
    position: relative;
    z-index: 2;
    cursor: pointer;
    transition: left 0.7s ease-out, opacity 1.5s ease-out;

    &:first-child {
        transition-delay: 0.5s;
    }

    &:nth-child(2) {
        transition-delay: 0.6s;
    }

    &:nth-child(3) {
        transition-delay: 0.7s;
    }

    &:nth-child(4) {
        transition-delay: 0.8s;
    }

    &:nth-child(5) {
        transition-delay: 0.9s;
    }

    &:nth-child(6) {
        transition-delay: 1s;
    }

    &:nth-child(7) {
        transition-delay: 1.1s;
    }

    &:nth-child(8) {
        transition-delay: 1.2s;
    }

    &:nth-child(9) {
        transition-delay: 1.3s;
    }

    &:nth-child(10) {
        transition-delay: 1.4s;
    }

    >* {
        position: relative;
        z-index: 5;
    }


    &:after {
        content: "";
        width: 0;
        height: 100%;
        display: block;
        background: $blue;
        position: absolute;
        top: 0;
        transition: width 0.2s ease-out;

    }

    @media(hover:hover) {
        &:hover {
            &:after {
                width: 100%;
            }

            img:not(.circle) {
                transform: translate(-50%, -50%) rotate(45deg);
            }
        }
    }

    &:nth-child(even) {
        &:after {
            right: 0;
        }

        .team__item-right {
            right: 100%;
            padding: 30px 0px 30px 25px;
        }

        @media(min-width:$grid-breakpoints-sm) {
            .team__close {
                left: -100%;
                right: initial;
                margin-right: 0;
                margin-left: 10px;

            }
        }
    }

    &:nth-child(odd) {
        &:after {
            left: 0;
        }

        .team__item-right {
            left: 100%;
            text-align: right;
            padding: 30px 25px 30px 0px;
        }
    }

    figure {
        height: 0;
        padding-top: 100%;
        position: relative;
        width: 100%;
        margin-bottom: 30px;
        z-index: 10;

        img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            max-width: 100%;

            &:not(.circle) {
                max-width: 120%;
                transition: transform 0.4s ease-out;
            }
        }
    }

    @media(max-width:$grid-breakpoints-sm) {

        h4 {
            margin-bottom: 20px;
        }

        &:after {
            content: none;
        }

        .team__item-right {
            position: static;
            opacity: 1;
            padding: 0px !important;
            text-align: left !important;
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.3s ease-out !important;
            transition-delay: 0 !important;
        }

        figure {
            padding-top: 80%;
            width: 80%;
            margin-left: auto;
            margin-right: auto;
        }
    }

    @media(max-width:$grid-breakpoints-xxl) {
        &:nth-child(even) {

            .team__item-right {

                padding: 35px 0px 20px 15px;
                right: calc(100% - 15px);
            }
        }

        &:nth-child(odd) {

            .team__item-right {

                padding: 35px 15px 20px 0px;
                left: calc(100% - 15px);
            }
        }
    }
}

.team__item-right {
    font-size: 14px;
    line-height: 1.5;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;
    position: absolute;
    z-index: -1;
    top: 0;
    opacity: 0;
    width: 0;

    p {
        font-size: 14px;
        line-height: 1.5;

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    @media(min-width:$grid-breakpoints-xl) and(max-width:$grid-breakpoints-xxl) {
        font-size: 12px;

        p {
            font-size: 12px;
        }
    }

    @media (min-width:$grid-breakpoints-sm) and (max-width:$grid-breakpoints-md),
    (min-width:$grid-breakpoints-lg) and(max-width:$grid-breakpoints-xl) {
        font-size: 11px;

        p {
            font-size: 11px;
        }

    }

}



.team__item.open {
    z-index: 10 !important;
    background: $blue;

    &:after {
        width: 200%;
    }

    .team__close {
        opacity: 1;
        z-index: 20;
        transition: all 0.3s ease-out;
        transition-delay: 0.2s;
    }

    figure img:not(.circle) {
        transform: translate(-50%, -50%) rotate(45deg);
        transform-origin: 50% 50%;
    }

    .team__item-right {
        opacity: 1;
        z-index: 10;
        width: 100%;
        transition: opacity 0.2s ease-out;
        transition-delay: 0.2s;
    }

    @media(max-width:$grid-breakpoints-xxl) {
        .team__item-right {
            width: calc(100% + 15px);
        }
    }

    @media(max-width:$grid-breakpoints-sm) {
        .team__item-right {
            max-height: 500px;
            display: block;
            width: 100%;
            left: 0;
            padding-bottom: 30px !important;
        }
    }
}



.team__right {

    position: relative;
    padding-bottom: 50px;
    z-index: 10;



    @media(max-width:$grid-breakpoints-sm) {
        p {
            font-size: 14px;
            line-height: 1.4;
        }
    }
}

.team__right-sticky {

    @media(min-width:$grid-breakpoints-lg) {
        position: sticky;
        top: 80px;

        &:before {
            content: "";
            display: block;
            width: calc(50vw - 10px);
            height: calc(100% + 120px);
            background: $bg-grey;
            position: absolute;
            top: -90px;
            left: -70px;
            z-index: -1;
        }
    }
}

.team__item-right {
    position: absolute;
    top: 0;


}


.team__manager {
    margin-bottom: 30px;


    figure {
        height: 300px;
        position: relative;
        min-width: 300px;
        max-width: 300px;
        z-index: 10;

        img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            max-width: 100%;

            &:not(.circle) {
                max-width: 120%;
                transition: transform 0.4s ease-out;
            }
        }

        @media(max-width:$grid-breakpoints-xxl) {
            min-width: 200px;
            max-width: 200px;
            height: 200px;
        }
    }

    a {
        color: $main;
    }

    @media(min-width:$grid-breakpoints-sm) {
        display: flex;
        align-items: center;
        gap: 30px;
    }

    @media(max-width:$grid-breakpoints-sm) {
        figure {
            margin: 0 auto 20px auto;
        }

        text-align:center;
    }
}

.team__quote {
    font-weight: 300;
    font-size: 34px;
    font-family: freight-big-pro, serif;
    margin-bottom: 70px;
    margin-top: 70px;

    @media(max-width:$grid-breakpoints-sm) {
        font-size: 30px;
        margin-bottom: 50px;
        margin-top: 50px;
    }

}


.insta__item--1 {
    background: $bg-grey;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .title {
        text-align: center;
        margin-bottom: 0;

        span {
            left: 0;
        }
    }
}

.insta__links {
    display: flex;
    gap: 40px;
}

.insta__list {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    margin-bottom: 5px;

    @media(max-width:$container-max-width-sm) {
        grid-template-columns: 1fr 1fr;
        grid-template-areas: "item1 item1"
            "item2 item3";

        .insta__item--1 {
            grid-area: item1;
            height: 150px;
            padding-left: 10px;
            padding-right: 10px;
        }

        li:nth-child(2) {
            grid-area: item2;
        }

        li:nth-child(3) {
            grid-area: item3;
        }

        .title {
            font-size: 52px;
        }
    }
}

.insta__list2 {

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 5px;

    @media(max-width:$container-max-width-sm) {
        grid-template-columns: 1fr 1fr;
    }
}

@keyframes rotate {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

@keyframes moveLeftRight {
    0% {
        left: 47%;
    }

    25% {
        left: 50%;
    }

    50% {
        left: 53%;
    }

    75% {
        left: 50%;
    }

    100% {
        left: 47%;
    }
}

.insta__link {
    width: 100%;
    height: 0;
    padding-top: 100%;
    position: relative;
    overflow: hidden;
    display: block;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.3s ease-out;
        z-index: 5;
    }



    &:before {
        content: url("../img/blur-4.svg");
        z-index: 10;
        top: 50%;
        left: 50%;
        position: absolute;
        // transform: translate(-50%, -50%);
        transition: all 0.3s ease-out;
        opacity: 0;
        animation: rotate 20s linear infinite;
    }

    &:after {
        content: url("../img/arrow-right-btn.svg");
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 12;
        position: absolute;
        opacity: 0;
        // animation: moveLeftRight 1s linear infinite;
    }

    @media (hover: hover) {
        &:hover {
            &:before {
                opacity: 0.5;
            }

            &:after {
                opacity: 1;
            }

            img {
                transform: translate(-50%, -50%) scale(1.1);
            }
        }
    }

    &:focus-visible {
        &:before {
            opacity: 0.5;
        }

        &:after {
            opacity: 1;
        }

        img {
            transform: translate(-50%, -50%) scale(1.1);
        }
    }
}

.insta.wow {
    .insta__link {
        img {
            width: 0;
            height: 0;
            transition: all 0.5s ease-out;
            opacity: 0;
        }
    }

    &.wow-loaded {
        .insta__link {
            img {
                width: 100%;
                height: 100%;
                opacity: 1;
            }
        }
    }
}

.fslightbox-container {
    background: rgba(255, 255, 255, 0.86) !important;
}

.fslightbox-slide-btn,
.fslightbox-toolbar {
    background: $main;

    * {
        fill: #fff;
    }
}








.header__2col {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 20px;
    flex-wrap: wrap;
    padding: 10px 0;

    * {
        margin-bottom: 0;
    }
}



.accordion {
    border-top: 1px solid #E8E9E5;

    .accordion__content {
        line-height: 2;
        overflow: hidden;
        margin-top: 0;
        transition: height 0.3s ease-out;
    }
}

.accordion__panel {
    border-bottom: 1px solid #E8E9E5;
}

.accordion__heading {
    margin-bottom: 0;
    ;
}

.accrdion__btn {
    width: 100%;
    display: block;
    position: relative;
    padding: 30px 30px 30px 0;
    background: none;
    border: none;
    font-size: 34px;
    line-height: 1.2;
    font-family: freight-big-pro, serif;
    text-align: left;
    color: $txt-dark;




    &.active {
        &:after {
            transform: translateY(-50%) rotate(0deg);
        }
    }

    &:before {
        content: "";
        display: block;
        width: 18px;
        height: 2px;
        transform: translateY(-50%);
        position: absolute;
        right: 4px;
        top: 50%;
        background: $txt;
    }

    &:after {
        content: "";
        display: block;
        width: 18px;
        height: 2px;
        transform: translateY(-50%) rotate(-90deg);
        position: absolute;
        right: 4px;
        top: 50%;
        background: $txt;
        transition: transform 0.3s ease-out;
    }

    @media(max-width:$grid-breakpoints-lg) {
        font-size: 30px;
    }

    @media(max-width:$grid-breakpoints-sm) {
        font-size: 24px;
        padding: 20px 30px 20px 0;
    }
}


.table__wrapper {
    background: rgba(241, 238, 229, 0.41);
    line-height: 1.1;


    @media(max-width:$grid-breakpoints-sm) {
        overflow: auto;

        table {
            min-width: 500px;
        }
    }

    table {
        border-collapse: collapse;
        width: 100%;

    }

    th {
        font-weight: 700;
        font-size: 14px;
    }

    tr:last-child {

        th,
        td {
            border-bottom: none;
        }
    }

    th,
    td {
        border-bottom: 1px solid #E8E9E5;
        padding: 15px 30px;
        line-height: 1.2;
        vertical-align: middle;

        &:not(:first-child) {
            text-align: right;
        }


        @media(max-width:$container-max-width-md) {
            padding: 10px 20px;
            font-size: 14px;
        }
    }

    th {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.priceList {
    padding-bottom: 50px;

    th,
    td {
        &:nth-child(2) {
            width: 100px;
            padding-left: 5px;
            padding-right: 5px;
        }

        &:nth-child(3) {
            width: 180px;
        }
    }

    @media(max-width:$container-max-width-md) {

        .accordion {
            margin-left: -20px;
            margin-right: -20px;
        }

        .accordion__heading {
            padding-left: 20px;
            padding-right: 20px;
        }

        th,
        td {
            &:first-child {
                padding-right: 10px;
                width: 100%;
            }

            &:nth-child(2) {
                white-space: nowrap;
            }

            &:last-child {
                padding-left: 10px;
            }
        }

        .table__wrapper {

            overflow: visible;

            table {
                min-width: 100%;
            }

            th,
            td {
                width: auto;
                font-size: 12px;
            }

            th {
                font-size: 10px;
            }
        }
    }
}



.tabs__nav-trigger {
    border: none;
    background: none;
    font-size: 21px;
    font-family: freight-big-pro, serif;
    font-weight: 300;
    padding: 12px 0;
    line-height: 1.2;
    text-align: left;
    color: #000;
    padding-left: 30px;
    position: relative;

    span {
        color: rgba(0, 0, 0, 0.5);
        font-size: 14px;
        margin-right: 3px;
        position: absolute;
        right: calc(100% - 25px);
        top: 16px;

    }

    &[aria-selected="true"],
    &:hover {
        color: $main;

        span {
            color: $main;
        }
    }
}

.tabs__panel {
    left: -1000px;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease-out;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fff;

    &.is-current {
        left: 0;
    }
}

.treatments__right {
    width: 100%;
}


.tabs__panels {
    overflow: hidden;
    width: 100%;
    position: relative;
    min-height: 550px;
}

.treatments__wrapper {
    padding-top: 30px;
    padding-bottom: 30px;

    @media(min-width:$grid-breakpoints-md) {
        display: flex;
        gap: 40px;

        .treatments__left {
            max-width: 200px;
            flex: 0 0 200px;
            border-right: 1px solid $line;
            padding-right: 30px;
        }
    }

    @media(min-width:$grid-breakpoints-lg) {
        gap: 110px;

        .treatments__left {
            max-width: 340px;
            flex: 0 0 340px;
            border-right: 1px solid $line-grey;
            padding-right: 30px;
        }
    }


}


.treatments__left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
    overflow: auto;

    @media(max-width:$grid-breakpoints-md) {
        li {
            padding-bottom: 5px;
        }

        padding-bottom:5px;
        flex-direction: row;
        justify-content: flex-start;
        padding-left:3px;
        padding-right:20px;
        margin-left:-15px;

        button {
            white-space: nowrap;
            padding: 12px 15px;
            border-bottom: 2px solid $line-grey;

            &[aria-selected="true"],
            &:hover {
                border-color: $main;
                outline: none;
            }

            span {
                display: none;
            }
        }

    }
}

.treatments__right {
    h3 {
        font-size: 40px;
        margin-bottom: 30px;
        font-weight: 300;
        color: #000;
    }

    @media(max-width:$grid-breakpoints-lg) {
        h3 {
            font-size: 34px;
            margin-bottom: 20px;
        }
    }

    @media(max-width:$grid-breakpoints-md) {
        .tabs__panel {
            display: block;
            padding-top: 20px;
        }
    }

}

.treatments__info {

    ul {
        list-style: disc;
        padding-left: 20px;
    }

    ol {
        list-style: decimal;
        padding-left: 20px;
    }




    figure {
        flex: 0 0 150px;
        width: 150px;
        height: 150px;
        position: relative;
        margin-bottom: 20px;

        img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }
    }

    p {
        margin-bottom: 30px;
        line-height: 1.6;
    }

    ol,
    ul {
        margin-bottom: 20px;
    }

    @media(min-width:$grid-breakpoints-lg) {
        display: flex;
        gap: 30px;
        max-width: 650px;
    }

    @media(min-width:$grid-breakpoints-xl) {
        figure {
            flex: 0 0 200px;
            width: 200px;
            height: 200px;
        }
    }

    @media(max-width:$grid-breakpoints-lg) {
        figure {
            float: right;
            margin-left: 10px;
        }
    }
}




.product__section {
    border-bottom: 1px solid $line;
    padding-top: 25px;
    padding-bottom: 25px;

    h2,
    h3 {
        font-size: 26px;
        margin-bottom: 20px;
    }

    ul,
    ol {
        padding-left: 15px;
        list-style: initial;
    }

    @media(min-width:$grid-breakpoints-sm) {

        h2,
        h3 {
            font-size: 30px;
        }
    }
}

.product__time {
    display: inline-flex;
    margin-top: 5px;
    margin-bottom: 10px;
    align-items: center;

    &:before {
        content: url("../img/clock.svg");
        margin-right: 7px;
    }

    strong {
        color: #000;
        margin-left: 5px;
    }

    padding-right:20px;

    @media(min-width: $grid-breakpoints-md) {
        padding-right: 50px;

        &:before {
            margin-right: 15px;
        }
    }
}


.atraction__item {

    margin-bottom: 50px;

    @media(min-width: $grid-breakpoints-md) {
        display: flex;
        align-items: center;


        .atraction__left,
        .atraction__right {
            width: 50%;
            flex: 0 0 50%;
        }

        &.atraction__item--inverse {
            .atraction__right {
                order: -1;
            }
        }

    }

    @media(min-width: $grid-breakpoints-xl) {
        margin-bottom: 100px;
    }
}

.atraction__left {
    img {
        max-width: 100%;
    }
}

.atraction__right {
    padding: 20px 0px;

    @media(min-width: $grid-breakpoints-md) {
        padding: 20px 30px;
    }

    @media(min-width: $grid-breakpoints-xxl) {
        padding: 20px 70px;
    }
}


.offert2 {
    padding-top: 20px;

    @media(min-width: $grid-breakpoints-sm) {
        padding-top: 50px;
    }

    @media(min-width: $grid-breakpoints-lg) {
        padding-top: 100px;
    }

    h2 {
        @media(min-width: $grid-breakpoints-md) {
            padding-right: 330px;
        }
    }
}

.offert2__wrapper {
    display: flex;
    justify-content: space-between;

    @media(max-width: $grid-breakpoints-md) {
        flex-wrap: wrap;
    }
}


.offert2__left {
    width: 100%;
    max-width: 730px;
}


.offert2__right {
    order: -1;
    width: 100%;
}



.offert2__blue {
    background: $main;
    padding: 30px;
    color: #fff;
    margin-bottom: 20px;

    .btn2 {
        width: 100%;
    }
}

.offer2__info {
    margin-bottom: 25px;
    text-align: right;
    font-size: 14px;
}

.offer2__price {
    display: flex;
    align-items: flex-start;
    padding-bottom: 15px;
    border-bottom: 1px solid $yellow;
    margin-bottom: 10px;

    span {
        text-decoration: line-through;
        margin-left: 5px;
        font-weight: 700;
    }

    strong {
        font-size: 40px;
        font-family: freight-big-pro, serif;
        margin-left: auto;
    }
}

.offert2__light {
    padding: 30px;
    border: 1px solid $line;

    ul {
        font-size: 14px;
        list-style: initial;
        padding-left: 15px;
        line-height: 1.2;

        li {
            margin-bottom: 10px;
        }
    }
}

.border-bottom-none {
    border-bottom: none;
}

.offert2__sticky {

    @media(min-width: $grid-breakpoints-md) {
        position: sticky;
        top: 100px;
        margin-top: -50px;
    }
}


@media(min-width: $grid-breakpoints-md) {

    .offert2__left {
        padding-right: 50px;
        padding-left: 0;
    }

    .offert2__right {
        order: 1;
        width: 330px;
        flex: 0 0 330px;
    }
}

@media(max-width: $grid-breakpoints-md) {

    .offert2__light {
        padding: 20px;
    }


    @media(min-width:370px) {
        .offert2__left {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}



.parallax {
    background-attachment: scroll;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 80vh;
    min-height: 500px;

    @media(hover:hover) {
        background-attachment: fixed;

    }

    @media(max-width:$grid-breakpoints-sm) {
        min-height: 350px;
        height: 50vh;
    }

}





.gallery {
    padding-top: 60px;

}

.gallery__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 2px;

    @media(max-width:$grid-breakpoints-sm) {
        grid-template-columns: 1fr 1fr;
    }

}

.gallery__link {
    height: 0;
    padding-top: 70%;
    position: relative;
    overflow: hidden;
    display: block;

    img {
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        object-fit: cover;
        transform: translate(-50%, -50%);
        transition: all 0.3s ease-out;
        position: absolute;
        z-index: 5;
    }

    &:after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        background: transparent;
        z-index: 10;
        transform: translate(-50%, -50%);
        transition: all 0.3s ease-in;
        position: absolute;
    }

    &:before {
        content: url("../img/btn-search-white.svg");
        top: 50%;
        left: 50%;
        background: transparent;
        z-index: 15;
        transform: translate(-50%, -50%);
        transition: all 0.3s ease-in;
        position: absolute;
        opacity: 0;
    }

    &:hover,
    &:focus {
        outline: none;

        img {
            transform: translate(-50%, -50%) scale(1.1);
        }

        &:after {
            background: rgb(13, 33, 83, 0.6);
        }

        &:before {
            opacity: 1;
        }
    }
}

.system__wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 40px;


    @media(min-width: $container-max-width-md) {
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        margin-top: 50px;
    }

    @media(min-width: $container-max-width-xxl) {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 30px;
    }
}

.system__item {
    padding: 10px 20px 40px 20px;
    border: 1px solid $line;

    img {
        padding: 0 20px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
    }
}

.system__title {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    line-height: 1;

    h3 {
        font-size: 22px;
        margin-bottom: 0;
    }

    span {
        margin-left: auto;
        display: block;
        padding-right: 10px;
        max-width: 100px;
        font-size: 14px;
    }

    strong {
        font-size: 80px;
        font-family: freight-big-pro, serif;
        color: $main;
    }


    @media(max-width:$grid-breakpoints-md) {
        strong {
            font-size: 60px;
        }
    }

    @media(max-width:$grid-breakpoints-sm) {
        flex-wrap: wrap;
        justify-content: center;

        h3 {
            width: 100%;
            text-align: center;
        }

        span {
            max-width: 70%;
            margin-left: initial;
        }
    }
}


.quest {
    background: $yellow-light;
    padding-top: 70px;
    padding-bottom: 60px;
}

.quest__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
    width: 100%;

    .quest__left {
        width: 100%;
    }

    .quest__right {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;
        flex-wrap: wrap;
    }

    @media(min-width:$grid-breakpoints-xxl) {
        max-width: 1050px;
    }

    a {
        white-space: nowrap;

        display: inline-block;
    }

    p {
        line-height: 1.4;
        font-size: 20px;
        font-weight: 600px;
    }

    .tel--big {
        font-weight: 700;
    }



    @media(max-width:$grid-breakpoints-md) {
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
    }
}

.tel--big {
    font-size: 30px;
    font-family: freight-big-pro, serif;
}

@media(max-width:$grid-breakpoints-sm) {
    .daterangepicker {

        .drp-buttons {
            text-align: center !important;
        }

        .drp-selected {
            width: 100%;
            padding: 5px 10px 10px 10px;

        }
    }
}

.links__wrapper {
    position: absolute;
    top: 115px;
    right: 20px;

    a {
        display: block;
        margin-bottom: 10px;

        img {
            border-radius: 7px;
        }

    }

    @media(max-width:$grid-breakpoints-xxl) {

        a img {
            max-width: 70px;
        }
    }


    @media(max-width:$grid-breakpoints-lg) {
        top: 90px;

        a img {
            max-width: 50px;
        }
    }

    @media(max-width:$grid-breakpoints-sm) {
        .link--camera {
            display: none;
        }
    }
}


.pagination {
    margin-top: 70px;
    padding-bottom: 60px;

    ul {
        display: flex;
        padding: 0;
        flex-wrap: wrap;
        justify-content: center;
        ;
        align-items: center;
        gap: 12px;

    }

    .prev,
    .next {
        position: relative;
        width: 48px;
        height: 48px;
        display: block;
        position: relative;

        &:before {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            content: url("../img/arrow-right.svg");

        }

        @media(min-width: $grid-breakpoints-xl) {
            width: 160px;
        }

        &:hover {
            right: 10px;
        }
    }

    .prev {
        &:before {
            content: url("../img/arrow-left.svg");

        }
    }
}

.pagination__item {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    ;
    align-items: center;
    font-weight: 700;
    font-size: 18px;
    border-radius: 6px;
    border: 1px solid $txt-dark;
    color: $txt-dark;

    &.active,
    &:hover {
        color: $main;
    }
}



.paginationArt {
    padding-top: 50px;
    padding-bottom: 50px;



    li {
        position: relative;
        padding-top: 15px;
        padding-bottom: 15px;

        a {
            display: flex;
            align-items: center;
            gap: 20px;
            width:100%;
        }

        &:after {
            position: absolute;
            bottom: 7px;
            left: 170px;
            content: url("../img/arrow-left-btn.svg");
        }

        &:nth-child(2) {
            h3 {
                order: -1;
                text-align: right
            }

            &:after {
                right: 170px;
                left: initial;
                content: url("../img/arrow-right-btn.svg");
            }
        }


    }

    h3 {
        font-weight: 300;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        line-clamp: 4;
        line-height: 1.2;
        -webkit-box-orient: vertical;
        margin-bottom: 18px;

        span {
            background: linear-gradient(to bottom, rgba(226, 195, 210, 0) 0%, rgba(226, 195, 210, 0) 81%, rgba(226, 195, 210, 1) 82%, rgba(226, 195, 210, 1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00e2c3d2', endColorstr='#e2c3d2', GradientType=0);
            background-position: 1px 93%;
            background-repeat: no-repeat;
            background-size: 0 20px;
            transition: background-size .3s ease-out;
        }

        @media(min-width:$grid-breakpoints-lg) {
            font-size: 28px;
        }
    }

    img {
        width: 135px;
        height: 135px;
        object-fit: cover;
        transition: all 0.3s ease-out;
    }

    @media(min-width:$grid-breakpoints-sm) {
        padding-top: 20px;
        padding-bottom: 20px;

        ul {
            display: flex;
            gap: 30px;
            justify-content: space-between;
        }

        li {
            max-width: 580px;
            flex: 0 1 50%;
        }

        h3 {
            -webkit-line-clamp: 3;
            line-clamp: 3;
        }
    }

    @media(min-width:$grid-breakpoints-lg) {
        li {
            a {
                gap: 40px;
            }
        }
    }





    @media(hover:hover) {
        a:hover {

            h3 span {
                background-size: 100% 20px;
            }
        }

    }
}



.bg--beauty {
    background: rgba(241, 238, 229, 0.41) !important;
}

.bg--nails {
    background: rgba(226, 195, 210, 0.2) !important;
}

.bg--hair {
    background: rgba(240, 214, 156, 0.14) !important;
}

.nails {

    .team__right:before,
    .table__wrapper {
        @extend .bg--nails;
    }

    .intro__subpageImg:before {
        background: rgba(255, 250, 253, 0.86) !important;
    }
}

.hair {

    .team__right:before,
    .table__wrapper {
        @extend .bg--hair;
    }

    .intro__subpageImg:before {
        background: rgba(254, 248, 235, 0.86) !important;
    }
}

.medicine {
    .intro__subpageImg:before {
        background: rgba(242, 247, 251, 0.86) !important;
    }
}

.only-mobile {
    @media(min-width:$grid-breakpoints-sm) {
        display: none;
    }
}

.only-desktop {
    @media(max-width:$grid-breakpoints-sm) {
        display: none;
    }
}


.col-sm-half {
    @media(min-width:$grid-breakpoints-sm) {
        max-width: 50%;
        flex: 0 0 50%;
    }
}



.black-section {
    background: #061405;
    overflow: hidden;
    margin-bottom: 50px;

    @media(min-width:$grid-breakpoints-md) {
        .container {
            display: flex;
            align-items: flex-end;

        }
    }

    @media(min-width:$grid-breakpoints-xxl) {
        .container {
            max-width: 1140px;

        }
    }

    figure {
        width: 100%;
        height: 0;
        padding-top: 100%;
        position: relative;
        text-align: right;

        img {
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%);
            height: 0;
            transition: height 0.7s ease-out;
        }
    }

    .black-section__txt {
        color: #fff;
        font-size: 26px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: relative;
        z-index: 10;
        min-height: 350px;



        p {
            margin-top: 30px;
            margin-bottom: 30px;

            max-width: 490px;
            font-family: freight-big-pro, serif;
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.7s ease-out;

        }
    }

    &.wow-loaded {
        figure img {
            height: 100%;
            ;
        }

        .black-section__txt p {
            max-height: 400px;
        }
    }
}