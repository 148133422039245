@import "components/variables";


.menu-open .header {
    background: $bg-grey;
}

.header {}

@keyframes fixedMenuDown {
    from {
        top: -100px
    }

    to {
        top: 0
    }
}

.fixed-header-scroll {
    background: $bg-grey !important;
    position: fixed;
    top: 0;
    animation-name: fixedMenuDown;
    animation-duration: .5s;

    .header__lang {
        display: none;
    }

    .header__wrapper {
        height: 60px;
    }

    .header__logo img {
        width: 60px;
    }

    &.header .link__reservation {
        margin-top: 0;
    }

    // .header__phone {
    //     display: none;
    // }

    .nav {
        top: 60px;
    }
}




.header__wrapper {
    display: flex;
    position: relative;
    z-index: 100;
    height: 84px;
    align-items: center;
    justify-content: space-between;
}

.header__logo {

    width: 90px;

    img {
        max-width: 100%;
    }

}


.header__lang {
    position: absolute;
    top: 180px;
    transform: rotate(-90deg) translateY(100%);
    right: -20px;
    display: flex;


    a {
        color: $txt;
        font-size: 9px;
        display: inline-block;
        padding: 10px 5px;
        text-transform: uppercase;

        &.active {
            font-weight: 700;
        }

        &:hover {
            color: $main;
        }
    }


}

.link__reservation {
    display: inline-block;
    padding: 18px 20px;
    border: 1px solid $main;
    border-radius: 7px;
    text-transform: uppercase;
    font-weight: 600;
    background: transparent;
    color: $main;
    font-size: 12px;

    &:hover {
        background: $main;
        color: #fff;

    }

    @media(max-width:$grid-breakpoints-xxl) {
        padding: 10px;
    }

}

.header {
    .link__reservation {
        margin-left: 50px;

        @media(max-width:$grid-breakpoints-xxl) {
            margin-left: 20px;
        }
    }
}

.header__phone {
    display: inline-block;
    padding: 10px;
    color: $txt;
    font-size: 12px;
    font-weight: 700;

    span {
        white-space: nowrap;
    }
}


.nav {
    margin-left: auto;
    margin-right: 50px;

    ul {
        li {
            position: relative;

            a {
                padding: 5px;
                color: $txt-dark;
                font-size: 12px;
                text-transform: uppercase;
                display: inline-block;
                white-space: nowrap;
                font-weight: 700;
                position: relative;
                width: 100%;
                outline: none;
            }

        }
    }
}

.is--desktop {
    .nav ul li a {
        &:hover {
            color: $main;
        }

        &:focus {

            text-decoration: underline;
        }
    }
}



.nav>ul {
    list-style: none;
    display: flex;
    gap: 30px;

    >li {
        &:not(:last-child) {
            &:after {
                content: "";
                display: block;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background: $main;
                position: absolute;
                right: -18px;
                top: 12px;
                font-size: 30px;
            }
        }
    }

    @media(max-width:$grid-breakpoints-xxl) {
        gap: 15px;

        >li {
            &:not(:last-child) {
                &:after {
                    right: -9px;
                }
            }
        }
    }

    ul {
        display: none;
        position: absolute;
        z-index: 100;
        top: 100%;
        left: 0;
        min-width: 250px;
        background: #fff;
        padding: 10px 20px;

        a {
            color: $main;
            line-height: 1.4;
        }
    }
}



.nav-toggle {
    min-width: 50px;
    width: 50px;
    height: 50px;
    padding: 4px 0 20px 0;
    background: transparent;
    border: none;
    display: block;
    display: none;
    position: relative;
    top: 2px;
    margin-left: 50px;

    small {
        color: #000;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        position: absolute;
        bottom: 2px;
        right: 0;

    }
}

.nav-toggle span {
    width: 32px;
    height: 2px;
    background: #000;
    transition: all 0.3s ease-in-out;
    display: block;
    backface-visibility: hidden;
    border-radius: 1px;
    left: 18px;
    position: absolute;


    &.one {
        top: 6px;
    }

    &.two {
        top: 16px;
        width: 20px;
        margin-left: 12px;
    }

    &.three {
        top: 25px;
    }
}

.nav-toggle.open {

    .two {
        display: none;
    }

    .one {
        position: absolute;
        top: 17px;
        left: 18px;
        transform: rotate(45deg);
        margin: 0;
    }

    .three {
        position: absolute;
        top: 17px;
        left: 18px;
        transform: rotate(-45deg);
        margin: 0;
    }
}


@media(max-width:$grid-breakpoints-xl) {


    .header__wrapper.container {
        max-width: 100%;
    }

    .menu__item--has-children {
        &:before {
            content: "";
            position: absolute;
            top: 20px;
            left: 35px;
            display: block;
            width: 12px;
            height: 12px;
            border-left: 2px solid $main;
            border-bottom: 2px solid $main;
            transform: rotate(-45deg);
        }

    }

    .header__phone {
        margin-left: auto;
    }

    .nav-toggle {
        display: block;
    }

    .nav {
        position: absolute;
        left: 0;
        top: 84px;
        background: #fff;
        width: 100%;
        display: none;
        padding: 20px 0;

        >ul>li>a {
            font-size: 24px;
            font-weight: 600;
        }

        ul {
            display: block;

            li {
                width: 100%;


                a {
                    color: $main;
                    width: 100%;
                    padding-left: 70px;
                    padding-right: 20px;
                    font-weight: 500;
                }
            }

            ul {
                width: 100%;
                position: relative;

                a {
                    font-size: 20px;

                    padding: 10px 10px 10px 50px;
                }
            }
        }
    }
}

@media(max-width:$grid-breakpoints-lg) {

    .subpage {
        .header__logo {
            top: 10px;
            left: 100px;

            img {
                height: 88px;
            }
        }

        .header__wrapper {
            padding-left: 20px;
        }

    }


}


@media(max-width:$grid-breakpoints-sm) {


    .nav {
        height: calc(100vh - 85px);
        overflow: auto;

        >ul {
            padding-bottom: 50px;
        }
    }

    // .header__phone {
    //     position: absolute;
    //     top: 5px;
    //     left: 50%;
    //     transform: translateX(-50%);
    //     margin-left: 15px;

    // }
    .header__phone {
        text-transform: uppercase;
    }

    .header .link__reservation {
        margin-right: 10px;
        margin-left: 5px;
        border: none;
    }

    .nav-toggle {
        margin-left: 0;
    }



}


.breadcrumbs {
    padding-top: 160px;


    ul {
        display: flex;

        span,
        a {
            font-size: 11px;
            color: $txt-dark;
            display: inline-block;
            text-transform: uppercase;
            padding: 5px 0;
            line-height: 1;
            font-weight: 700;
        }

        a {
            margin-right: 10px;

            &:after {
                margin-left: 10px;
                color: $txt-dark;
                content: "/";

            }

            &:focus,
            &:hover {
                outline: none;
                color: $yellow;

                &:after {
                    color: $txt-dark;
                }

            }
        }
    }
}