@import "variables";

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
figure,
figcaption,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-weight: inherit;
    font-style: inherit;
    font-size: 100%;
    font-family: inherit;
    vertical-align: baseline;
}

iframe,
video {
    max-width: 100%;
    width: 100%;

}

strong {
    font-weight: 700;
}

.bold {
    font-weight: 700 !important;
}

.font--12 {
    font-size: 12px !important;
}

.font--18 {
    font-size: 18px !important;
}

.font--0 {
    font-size: 20px !important;
}

.txt-center {
    text-align: center !important;
}


input:not([type="date"], [type="number"]),
textarea,
button {
    -webkit-appearance: none;
    border-radius: 0;
}

button {
    padding: 0;
}

/* remember to define focus styles! */
:focus {
    outline: none;
}

:focus-visible {
    outline: 2px solid $main;
    outline-offset: 2px;
}

a,
button {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

a {
    color: $main-light;
    text-decoration: none;


    &:hover {
        color: $main;
    }
}

body {
    line-height: 1.2;
    font-size: 16px;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    color: $txt;
    font-style: normal;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

/* tables still need 'cellspacing="0"' in the markup */
table {
    border-collapse: separate;
    border-spacing: 0;
    color: $txt-dark;
}

caption,
th,
td {
    text-align: left;
    font-weight: normal;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
}



h1,
h2,
h3 {
    font-family: freight-big-pro, serif;

    color: #000;
    line-height: 1.2;
    letter-spacing: initial;

    a {
        color: inherit;
    }
}

h1,
.h1 {
    font-size: 95px;
    margin-bottom: 30px;
    font-weight: 300;
}

h2 {
    font-size: 40px;
    margin-bottom: 25px;
    font-weight: 300;
}

h3 {
    font-size: 32px;
    margin-bottom: 15px;
    margin: 20px 0 15px;
    font-weight: 500;
}

.title {
    margin-bottom: 50px;
    font-size: 78px;
    font-weight: 300;
    line-height: 0.60;
    color: #000;
    position: relative;

    transition: left 0.5s ease-in-out, opacity 1s ease-in-out;

    &.wow {
        left: -50px;
        opacity: 0;
    }

    span {
        display: block;
        color: $txt;
        opacity: 0.7;
        position: relative;
        left: 70px;
    }

    @media(max-width:$grid-breakpoints-lg) {
        font-size: 64px;
        margin-bottom: 30px;

        &.wow {}

        span {
            left: 60px;
        }
    }

    @media(max-width:$grid-breakpoints-sm) {
        font-size: 60px;

        span {
            left: 56px;
        }
    }

    &.wow-loaded {
        left: 0px;
        opacity: 1;
    }
}





.title--big {
    margin-bottom: 50px;
    font-size: 96px;
    font-weight: 300;
    line-height: 0.60;
    color: #000;
    padding-left: 100px;

    span {
        display: block;
        color: $txt;
        opacity: 0.7;
        position: relative;
        left: -100px;

    }
}

.title--2 {
    font-size: 74px;
    font-family: freight-big-pro, serif;
    font-weight: 300;

}


@media(max-width:$grid-breakpoints-lg) {

    h1,
    .h1 {
        font-size: 70px;
    }

    h2 {
        font-size: 36px;
    }

    h3 {
        font-size: 20px;
    }

    .title--2 {
        font-size: 64px;
    }
}

@media(max-width:$grid-breakpoints-sm) {

    h1,
    .h1 {
        font-size: 60px;
    }

    h2 {
        font-size: 34px;
        margin-bottom: 20px;
    }

    h3 {

        font-size: 18px;
    }

    .title--2 {
        font-size: 60px;
    }
}

h4,
h5,
h6 {
    line-height: 1.5;
    font-size: 16px;
}

.h--2 {
    margin-bottom: 25px;
    color: $main;

    @media(max-width:$grid-breakpoints-sm) {
        text-align: center;
        font-size: 40px;
    }
}




p,
ul,
ol {
    line-height: 1.6;
}

ul,
ol {
    list-style: none;

}

.page__txt {

    h1,
    .title--2 {
        font-size: 40px;
    }

    h2 {
        font-size: 30px;
    }

    h3 {
        font-size: 20px;
    }

    ul,
    ol {
        list-style: initial;
        padding-left: 30px
    }

    ol,
    ul,
    p {
        margin-bottom: 15px;
    }

    li {
        margin-bottom: 5px;
    }

    a {
        text-decoration: underline;
    }

    @media(max-width:$grid-breakpoints-sm) {

        h1,
        .title--2 {
            font-size: 30px;
        }

        h2 {
            font-size: 24px;
        }

        h3 {
            font-size: 18px;
        }
    }
}


a,
span,
strong {
    line-height: inherit;
}

.link--underline {
    text-decoration: underline;
    color: $txt;

    @media(hover:hover) {
        &:hover {
            color: $main;
        }
    }
}

.link--underline-main {
    text-decoration: underline;
    color: $main;

    @media(hover:hover) {
        &:hover {
            color: #000;
        }
    }
}




.link--dark {
    color: $txt;

    @media(hover:hover) {
        &:hover {
            color: $main;
        }
    }
}

.container {
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: $container-max-width-sm;

    @media (min-width: $grid-breakpoints-md) {
        max-width: $container-max-width-md;
    }

    @media (min-width: $grid-breakpoints-lg) {
        max-width: $container-max-width-lg;
    }

    @media (min-width: $grid-breakpoints-xl) {
        max-width: $container-max-width-xl;

        &.container--md {
            max-width: 960px;
        }

    }

    @media (min-width: $grid-breakpoints-xxl) {
        max-width: $container-max-width-xxl;

    }
}

.container--half {
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: $container-max-width-sm;

    @media (min-width: $grid-breakpoints-md) {
        max-width: calc($container-max-width-md / 2);
    }

    @media (min-width: $grid-breakpoints-lg) {
        max-width: calc($container-max-width-lg / 2);
    }

    @media (min-width: $grid-breakpoints-xl) {
        max-width: calc($container-max-width-xl / 2);
    }

    @media (min-width: $grid-breakpoints-xxl) {
        max-width: calc($container-max-width-xxl / 2);
    }
}


.row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
    margin-right: 20px;
}

[class*="col-"] {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
}


@media (min-width: $grid-breakpoints-md) {
    .col-md-50 {
        width: 50%;
        flex: 0 0 50%;
    }
}

@media (min-width: $grid-breakpoints-lg) {
    .col-lg-50 {
        width: 50%;
        flex: 0 0 50%;
    }
}

.header__fixed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
}



.img--fluid {
    max-width: 100%;
    height: auto;
}

.box--center {
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.nowrap {
    white-space: nowrap !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.mt-50 {
    margin-top: 50px !important;
}

.mt-100 {
    margin-top: 100px !important;

    @media(max-width:$grid-breakpoints-md) {
        margin-top: 50px !important;
    }
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mb-50 {
    margin-bottom: 50px !important;
}


input,
textarea,
select {

    border: 1px solid $line;
    line-height: 1.6;
    padding: 10px 15px;
    color: $input;

    &::placeholder {
        color: $line;
        ;
    }

}


.btn2 {
    border: none;
    color: $main;
    text-transform: uppercase;
    padding: 22px;
    font-size: 15px;
    white-space: nowrap;
    position: relative;
    display: inline-block;
    background: $yellow;
    font-weight: 600;
    text-align: center;
    transition: all 0.3s ease-in-out;

}

.btn {
    border: none;
    background: transparent;
    color: $txt-dark;
    text-transform: uppercase;
    padding: 20px 100px 20px 15px;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 700;
    white-space: nowrap;
    position: relative;
    display: inline-block;
    transition: all 0.3s ease-in-out;
    border: 1px solid $txt-dark;
    line-height: 0.5;

    &:after {
        content: url("../img/arrow-right-btn.svg");
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 25px;
        transition: right 0.3s ease-in-out;
    }
}

.btn--light {
    background: $blue-bg;
    color: $main;

    &:after {
        content: url("../img/arrow-right.svg");
    }
}

.btn--white {
    background: #fff;
    color: $main;
    border: 1px solid $line;

    &:after {
        content: url("../img/arrow-yellow.svg");
    }
}

.btn--back {
    padding: 20px 30px 20px 100px;


    &:after {
        right: initial;
        left: 30px;
        transition-property: left;
        content: url("../img/arrow-left-btn.svg");
    }
}

.is--desktop {
    .btn:hover {
        &:after {
            right: 15px;
        }

        color:$txt-dark;
        outline: none;
        box-shadow: 0 5px 5px -5px $txt;


        &.btn--back {
            &:after {
                right: initial;
                left: 15px;
            }
        }
    }

    .btn__parent:hover {

        .btn {
            &:after {
                right: 15px;
            }

            color:$txt-dark;
            outline: none;
        }
    }

    .btn2 {

        &:hover,
        &:focus {
            outline: none;
            background: #fbdd6b;
        }
    }
}


.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 120;
    padding: 30px;
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s linear 0.1s, opacity 0.3s ease;

    &.open {
        visibility: visible;
        opacity: 1;
        transition-delay: 0s;
    }

    .link--underline-main {
        display: block;
        margin-bottom: 10px;
    }

    p {
        margin-bottom: 20px;
    }

    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 21;
        background-color: rgba(0, 0, 0, 0.7);
    }

    &__close {
        position: absolute;
        top: 0px;
        right: 0px;
        border: none;
        outline: none;
        background: none;
        width: 60px;
        height: 60px;
        display: inline-block;
        background: $txt;
        color: #fff;
        padding: 0;

        &:after {
            content: "+";

            transform: rotate(45deg);
            font-size: 40px;
            position: absolute;
            top: 6px;
            left: 20px;
            transition: all 0.3s ease-out;

        }

        &:hover {
            background: $main;
        }
    }

    .modal__content {
        width: 100%;
        overflow: auto;
        max-height: 80vh;
    }

    &__container {
        position: relative;
        z-index: 22;
        max-width: 1520px;
        width: 96%;
        height: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 5%;
        margin: 0 auto;
        padding: 100px 140px 100px 140px;
        background-color: #fff;



        @media(max-width:1200px) {
            padding: 65px;
        }

        @media(max-width:$grid-breakpoints-lg) {
            padding: 50px 20px 20px 20px;
            height: auto;
            max-height: 95%;
        }

        @media(max-width:$grid-breakpoints-sm) {
            padding: 40px 20px 20px 20px;
            align-items: flex-start;
        }
    }

    &__title {

        padding-right: 20px;
        margin-bottom: 15px;
        text-transform: uppercase;
        font-size: 40px;
        text-align: left;
        margin-top: 0;
        font-weight: 700;
    }



    h4 {
        font-family: freight-big-pro, serif;
        font-size: 31px;
        margin-bottom: 10px;
        color: #000;
    }

    @media(max-width:$grid-breakpoints-lg) {
        &__title {

            font-size: 24px;
        }

        h4 {
            font-size: 20px;
            margin-bottom: 5px;
        }

        &__close {
            width: 40px;
            height: 40px;

            &:after {
                top: -3px;
                left: 9px;
            }
        }

    }

    ul {
        list-style: disc;
        padding-left: 30px;
    }

}

.modal__row {

    position: relative;

    @media(min-width:$grid-breakpoints-lg) {
        padding-bottom: 80px;
    }

    >div {
        width: 50%;
    }


    .img-res {

        height: 100%;
        width: 45%;
        max-width: 45%;
        position: absolute;
        top: 0;
        right: 0;
        overflow: hidden;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    .link__reservation {
        padding: 12px 20px;
    }


    @media(max-width:$grid-breakpoints-sm) {

        padding-bottom: 215px;


        >div {
            width: 100%;
        }



        .img-res {
            width: 100%;
            max-width: 100%;
            height: 200px;
            top: initial;
            bottom: 0;


            img {
                height: auto;
                position: absolute;
                left: 50%;
                top: 38%;
                transform: translate(-50%, -50%);

            }
        }

        p,
        ul {
            font-size: 14px;
            margin-bottom: 12px;
        }


        @media(max-width:420px) {
            padding-bottom: 175px;

            .img-res {
                height: 160px;
            }
        }

    }
}

.phone-big {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 35px;
    display: block;
    color: $main;
    font-family: freight-big-pro, serif;

    @media(max-width:$grid-breakpoints-sm) {
        font-size: 26px;
        margin-bottom: 15px;
    }
}

.modal-flex {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-top: 20px;

    img {
        width: 130px;
    }

    @media(max-width:$grid-breakpoints-sm) {
        margin-top: 15px;

        img {
            width: 90px;
        }
    }

}